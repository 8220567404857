import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaUserCircle } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { PacmanLoader } from 'react-spinners';
import { DataContext } from '../../context/DataContext';
import { FaPlus } from "react-icons/fa";
import axios from 'axios';
import StatusColumn from '../../components/StatusColumn/StatusColumn';

const NewKanban = () => {
    const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
    const { adminData, user, country } = useContext(AuthContext);
    const { setFundedThisMonth, setStaffComs, setSearchRequestValue, searchRequestValue } = useContext(DataContext);
    const [dealList, setDealList] = useState([]);
    const [loanList, setLoanList] = useState([]);
    const [metaData, setMetaData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [warmleads, setWarmleads] = useState([]);
    const navigate = useNavigate();
    const containerRef = useRef(null);

    const calculateTotalSum = (data) => {
        const totalSum = data.reduce((sum, item) => {
            const value = parseFloat(item['required-amount']) || 0;
            return sum + value;
        }, 0);

        return totalSum.toLocaleString("en-US");
    };

    const calculateTotalSumofFundedAmount = (data) => {
        const totalSum = data.reduce((sum, item) => {
            const value = parseFloat(item['funded-amount']) || 0;
            return sum + value;
        }, 0);

        return totalSum.toLocaleString("en-US");
    };
    const calculateTotalSumOfComs = (data) => {
        const totalSum = data.reduce((sum, item) => {
            const value = parseFloat(item['staff-coms']) || 0;
            return sum + value;
        }, 0);
        console.log(data);
        return totalSum.toLocaleString("en-US");
    };



    useEffect(() => {
        axios.post(`${serverURL}/api/v3/new-monthly-funded-coms`, {
            email: user.email
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                return response.data;
            })
            .then(data => {
                setFundedThisMonth(parseInt(data?.totalFundedAmount).toLocaleString('en-us') || 0);
                setStaffComs(data?.totalComs || 0);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [])


    // DND ITEMS
    const [activeId, setActiveId] = useState(null);
    const [activeItem, setActiveItem] = useState({});

    const searchResult = (e) => {
        e.preventDefault();
        let tempObject = {};
        if (e.target["business-name"].value && e.target["business-name"].value !== null) {
            tempObject = { ...tempObject, "business-name": e.target["business-name"].value }
        }
        if (e.target["lender"].value && e.target["lender"].value !== "Lender") {
            tempObject = { ...tempObject, "lender": e.target["lender"].value }
        }
        if ((adminData.role === "super-admin" || user.email === "smit@readysteadyfinance.co.uk" || user.email === "millie@sedgefunding.co.uk") && e.target["funding-manager"].value && e.target["funding-manager"].value !== "Funding Manager") {
            tempObject = { ...tempObject, "funding-manager": e.target["funding-manager"].value }
        }
        if (e.target["category"].value && e.target["category"].value !== "Category") {
            tempObject = { ...tempObject, "category": e.target["category"].value }
        }
        setSearchRequestValue(tempObject)
    }

    // DND Handlers
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    function handleDragStart(event) {
        const { active } = event;
        const { id } = active;
        setActiveId(id);
        setActiveItem(loanList.find((i) => i._id === active.id));
    }

    // This is the function that handles the sorting of the containers and items when the user is done dragging.
    async function handleDragEnd(event) {
        const { active, over } = event;
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan-status/${event.active.id}/${event.over.data.current.sortable.containerId}`, {
                method: 'POST',
            });

            if (response.ok) {
                fetch(`${process.env.REACT_APP_SERVER_URL}/add-loan-edit-history/${event.active.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ history: [{ user: user.displayName, log: ['status'] }] })
                })
                    .then(response => response.json())
                    .then(result => { })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }

        if (!over) return;
        const { id } = active;
        const { id: overId } = over;

        // Find the containers
        const activeContainer = findStatus(loanList, id);
        const overContainer = findStatus(loanList, overId);
        if (activeContainer && overContainer) {
            const activeIndex = loanList.findIndex((t) => t._id === activeId);
            const overIndex = loanList.findIndex((t) => t._id === overId);

            setDealList((loanList) => {
                if (loanList[activeIndex].status !== loanList[overIndex].status) {
                    // Fix introduced after video recording
                    loanList[activeIndex].status = loanList[overIndex].status;
                    return arrayMove(loanList, activeIndex, overIndex - 1);
                }
                return arrayMove(loanList, activeIndex, overIndex);
            });
        }
        const isOverAColumn = over.data.current?.type === "Status";

        // Im dropping a Task over a column
        if (isOverAColumn) {
            setDealList((loanList) => {
                const activeIndex = loanList.findIndex((t) => t._id === activeId);
                loanList[activeIndex].status = overId;
                console.log("DROPPING TASK OVER COLUMN", { activeIndex });
                return arrayMove(loanList, activeIndex, activeIndex);
            });
        }

    }

    const findStatus = (objectsArray, id) => {
        const targetObject = objectsArray.find(obj => obj._id === id);
        // If the object is found, return its status, otherwise return null or an appropriate default value
        return targetObject ? targetObject.status : null;
    };


    // new code line start
    function handleDragOver(event) {
        const { active, over } = event;
        if (!over) return;
        const { id } = active;
        const { id: overId } = over;
        if (activeId === overId) return;

        // Find the containers
        const activeContainer = findStatus(loanList, id);
        const overContainer = findStatus(loanList, overId);

        if (activeContainer && overContainer) {
            setDealList((loanList) => {
                const activeIndex = loanList.findIndex((t) => t._id === activeId);
                const overIndex = loanList.findIndex((t) => t._id === overId);
                if (loanList[activeIndex].status !== loanList[overIndex].status) {
                    // Fix introduced after video recording
                    loanList[activeIndex].status = loanList[overIndex].status;
                    return arrayMove(loanList, activeIndex, overIndex - 1);
                }
                return arrayMove(loanList, activeIndex, overIndex);
            });
        }

        const isOverAColumn = over.data.current?.type === "Status";
        if (isOverAColumn) {
            setDealList((loanList) => {
                const activeIndex = loanList.findIndex((t) => t._id === activeId);

                loanList[activeIndex].status = overId;
                return arrayMove(loanList, activeIndex, activeIndex);
            });
        }
    }

    const handleDragOverk = (e) => {
        e.preventDefault();
        const container = containerRef.current;
        if (!container) return;

        const { clientX } = e;
        const { left, right } = container.getBoundingClientRect();

        if (clientX < left + 50) {
            container.scrollLeft -= 10; // Scroll left
        } else if (clientX > right - 50) {
            container.scrollLeft += 10; // Scroll right
        }
    };

    const removeSrarchFilters = () => {
        window.location.reload();
    }

    const reduceTotalLoansNumber = (statusName) => {
        const updatedMetaData = metaData.map(item => {
            if (item?.status === statusName) {
                const updatedTotalLoans = item.totalLoans - 1;
                return { ...item, totalLoans: updatedTotalLoans };
            }
            return item;
        });

        setMetaData(updatedMetaData);
    }

    const increaseTotalLoansNumber = (statusName) => {
        const updatedMetaData = metaData.map(item => {
            if (item?.status === statusName) {
                const updatedTotalLoans = item.totalLoans + 1;
                return { ...item, totalLoans: updatedTotalLoans };
            }
            return item;
        });

        setMetaData(updatedMetaData);
    }

    if (loading) {
        return <div className='text-4xl mt-[35vh] flex justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }

    return (
        <div>
            <form onSubmit={(e) => searchResult(e)} className='px-5 mb-5 w-full max-w-full p-3 mt-5 mr-10 bg-base-200 rounded-lg flex justify-between items-center '>
                <div className='flex items-center justify-center gap-2'>
                    <div className='flex gap-5'>
                        <input name='business-name' id='searchFilterName' placeholder='Search By Name....' type="text" className="input h-10 input-bordered  text-sm" defaultValue={searchRequestValue["business-name"] || null} />
                        <select name='lender' id='searchFilterType' className="input input-bordered h-10 text-sm" defaultValue={searchRequestValue[""] || null}>
                            <option value={searchRequestValue["lender"] || ""} >{searchRequestValue["lender"] || "Lender"}</option>
                            <option value="New Deal">New Deal</option>
                            <option value="YouLend">YouLend</option>
                            <option value="365 Finance">365 Finance</option>
                            <option value="IWOCA">IWOCA</option>
                            <option value="Funding Circle">Funding Circle</option>
                            <option value="Funding Alternative">Funding Alternative</option>
                            <option value="Swiftfund">Swiftfund</option>
                            <option value="Maxcap">Maxcap</option>
                            <option value="Bizcap">Bizcap</option>
                            <option value="Capify">Capify</option>
                            <option value="Playter">Playter</option>
                            <option value="Bibby">Bibby</option>
                            <option value="Penny">Penny</option>
                        </select>
                        {
                            (adminData.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && <select name='funding-manager' id='searchFilterManager' className="input h-10 text-sm input-bordered" defaultValue={searchRequestValue["funding-manager"] || null}>
                                <option value={searchRequestValue["funding-manager"] || "Funding Manager"} >{searchRequestValue["funding-manager"] || "Funding Manager"}</option>
                                <option value="Pending" >Pending</option>
                                <option value="Wayne">Wayne</option>
                                <option value="Peter">Peter</option>
                                <option value="Smit">Smit</option>
                                <option value="Jon">Jon</option>
                                <option value="Millie">Millie</option>
                                <option value="Mark">Mark</option>
                                <option value="Becher">Becher</option>
                                <option value="Don">Don</option>
                                <option value="Carl">Carl</option>
                                <option value="Jonnalou">Jonnalou</option>
                                <option value="Janne">Janne</option>
                                <option value="ON_HOLD">ON_HOLD</option>
                            </select>
                        }
                        {
                            user.email === "smit@readysteadyfinance.co.uk" && <select name='funding-manager' id='searchFilterManager' className="input h-10 text-sm input-bordered" defaultValue={searchRequestValue["funding-manager"] || null}>
                                <option value={searchRequestValue["funding-manager"] || "Funding Manager"} >{searchRequestValue["funding-manager"] || "Funding Manager"}</option>
                                <option value="Pending" >Pending</option>
                                <option value="Smit">Smit</option>
                                <option value="Mark">Mark</option>
                                <option value="Becher">Becher</option>
                                <option value="Don">Don</option>
                                <option value="Carl">Carl</option>
                                <option value="Jonnalou">Jonnalou</option>
                                <option value="Janne">Janne</option>
                            </select>
                        }
                        <select name='category' id='searchFilterCategory' className="input input-bordered h-10 text-sm" defaultValue={searchRequestValue[""] || null}>
                            <option value={searchRequestValue["category"] || ""} >{searchRequestValue["category"] || "Category"}</option>
                            <option value="Resturant">Resturant</option>
                            <option value="Convenient Store">Convenient Store</option>
                        </select>
                    </div>
                    <button type='button' onClick={removeSrarchFilters} className="text-xl hover:bg-base-100 p-3 rounded-lg"><IoCloseOutline /></button>
                </div>
                <div>
                    <button type='submit' className="button mr-3">Search</button>
                    <Link to={'/admin/add-single-deal'} className='button'>Request Finance</Link>
                    {/* {(adminData.role === "super-admin" || adminData.role === "admin") && <button type='button' onClick={addNewLead} className="button flex justify-center items-center ml-3 gap-1"><FaPlus /></button>} */}
                </div>
            </form>

            <div ref={containerRef} onDragOver={handleDragOverk} className='flex gap-3 overflow-x-auto'>

                {/* Initial Review */}
                <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Initial Review'} loanList={loanList} />

                {/* Additional Documents */}
                {/* {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Additional Documents'} loanList={loanList} />} */}

                {/* Golden Data */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Golden Data'} loanList={loanList} />}

                {/* The Great Takeover */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'The Great Takeover'} loanList={loanList} />}

                {/* Pre-Underwriting */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Pre-Underwriting'} loanList={loanList} />}

                {/* Pre-Offer */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Pre-Offer'} loanList={loanList} />}

                {/* Underwriting */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Underwriting'} loanList={loanList} />}

                {/* Extra docs/ ob */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Extra docs ob'} loanList={loanList} />}

                {/* Awaiting Offer */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Awaiting Offer'} loanList={loanList} />}

                {/* Offer */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Offer'} loanList={loanList} />}

                {/* Contract Sent */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Contract Sent'} loanList={loanList} />}

                {/* Awaiting Funding */}
                {adminData?.team !== 'call-center' && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Awaiting Funding'} loanList={loanList} />}

                {/* Funded This month */}
                <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Funded This Month'} loanList={loanList} />

                {/* Funded */}
                {(adminData?.role === "super-admin" || user.email === 'smit@readysteadyfinance.co.uk' || user.email === 'millie@sedgefunding.co.uk') && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Funded'} loanList={loanList} />}

                {/* Last Chance */}
                {(adminData?.role === "super-admin") && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Last Chance'} loanList={loanList} />}

                {/* Cancelled */}
                {(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk" || user.email === "smit@readysteadyfinance.co.uk") && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Cancelled'} loanList={loanList} />}

                {/* Cancelled for ever */}
                {(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk" || user.email === "smit@readysteadyfinance.co.uk") && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Cancelled for ever'} loanList={loanList} />}

                {/* Qualified */}
                {/* {(adminData?.role === "super-admin" || adminData?.team === "call-center" || adminData?.team === "New Business Team") && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Qualified'} loanList={loanList} />} */}

                {/* Non-Qualified */}
                {/* {(adminData?.role === "super-admin" || adminData?.team === "call-center" || adminData?.team === "New Business Team") && <StatusColumn reduceTotalLoansNumber={reduceTotalLoansNumber} increaseTotalLoansNumber={increaseTotalLoansNumber} setMetaData={setMetaData} metaData={metaData} setLoanList={setLoanList} setLoading={setLoading} status={'Non-Qualified'} loanList={loanList} />} */}
            </div>
        </div>
    )
}

export default NewKanban
