import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import ViewLoan from '../../components/ViewLoan/ViewLoan';
import EditLoan from '../../components/EditLoan/EditLoan';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { FaRegEye } from 'react-icons/fa';
import LoanPdfList from '../../components/LoanPdfList/LoanPdfList';
import { Link } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import { IoMdSearch } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoCloseOutline } from 'react-icons/io5';

const NewDashboard = () => {
    const [loanList, setLoanList] = useState([]);
    const [viewLoan, setViewLoan] = useState({});
    const [editLoan, setEditLoan] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);
    const [pdflist, setPdflist] = useState([]);
    const [sortByDateState, setSortByDateState] = useState(false);
    const [sortByFundedAmount, setSortByFundedAmount] = useState(false);
    const [sortByRequiredAmount, setSortByRequiredAmount] = useState(false);
    const [searchRequestValue, setSearchRequestValue] = useState({});
    const [page, setPage] = useState(1); // Initialize page state
    const [limit, setLimit] = useState(700); // Initialize limit state
    const [totalPages, setTotalPages] = useState(1);
    const [businessName, setBusinessName] = useState();
    const [businessAddress, setBusinessAddress] = useState();
    const [phone, setPhone] = useState();
    const [ownerName, setOwnerName] = useState();
    const [email, setEmail] = useState();

    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
        setEditLoan({})
    }

    const handlePdfLinkClick = (e, item) => {
        e.preventDefault(); // Prevent the default behavior of the anchor element

        getDownloadURL(item)
            .then((url) => {
                // Once you have the URL, open it in a new tab/window
                window.open(url, '_blank');
            })
            .catch((error) => {
                console.error('Error getting download URL:', error);
            });
    };

    const sortDataByDate = () => {
        setSortByDateState(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateA - dateB;
        })
        setLoanList(sortedData)
    };

    const sortDataByDateRevarse = () => {
        setSortByDateState(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        })
        setLoanList(sortedData)
    };

    const sortDataByFundedAmount = () => {
        setSortByFundedAmount(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        })
        setLoanList(sortedData)
    };
    const sortDataByFundedAmountReverse = () => {
        setSortByFundedAmount(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        })
        setLoanList(sortedData)
    };

    const sortDataByRequirements = () => {
        setSortByRequiredAmount(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        })
        setLoanList(sortedData)
    };
    const sortDataByRequirementsReverse = () => {
        setSortByRequiredAmount(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        })
        setLoanList(sortedData)
    };

    const updateSearchRequestValues = (e) => {
        setSearchRequestValue({
            ...searchRequestValue,
            [e.target.name]: e.target.value
        })
    }

    const updateNameSearchRequestValues = (e) => {
        e.preventDefault();
        // console.log(e.target["business-name"].value);
        setSearchRequestValue({
            ...searchRequestValue,
            "business-name": e.target["business-name"].value
        })
    }

    // useEffect(() => {
    //     setLoanList([]);
    //     setLoading(true)
    //     const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/new-dashboard/all-deals`;
    //     const requestBody = JSON.stringify({
    //         queryObj: searchRequestValue,
    //         page, 
    //         limit 
    //     });

    //     fetch(url, {
    //         method: 'POST',
    //         body: requestBody,
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             const sortedData = [...data.loans].sort((a, b) => {
    //                 const dateA = new Date(a.createdDate);
    //                 const dateB = new Date(b.createdDate);
    //                 return dateB - dateA;
    //             });
    //             setLoanList(sortedData);
    //             setTotalPages(data.totalPages);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             setLoading(false);
    //             console.error('Error:', error);
    //         });
    // }, [searchRequestValue, page])

    const fetchData = (queryObj = {}, pageNumber) => {
        setLoading(true);
        const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/new-dashboard/all-deals`;

        const requestBody = JSON.stringify({
            queryObj,
            page: pageNumber,
            limit
        });

        fetch(url, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setTotalPages(data.totalPages)
                const sortedData = [...data.loans].sort((a, b) => {
                    const dateA = new Date(a.createdDate);
                    const dateB = new Date(b.createdDate);
                    return dateB - dateA;
                });
                setLoanList(sortedData);
                setLoading(false);
            })
            .catch(error => {
                alert('No New Data Found')
                setLoading(false);
                console.error('Error:', error);
            });
    };

    // Fetch all data on the first load
    useEffect(() => {
        fetchData({}, page);
    }, []);

    // Handle search when the search button is clicked
    const handleSearch = () => {
        const queryObj = {};

        if (businessName) queryObj.businessName = businessName;
        if (businessAddress) queryObj.businessAddress = businessAddress;
        if (phone) queryObj.phone = phone;
        if (ownerName) queryObj.ownerName = ownerName;
        if (email) queryObj.email = email;

        fetchData(queryObj); // Fetch filtered data based on the search inputs
    };

    const rows = ["Company Name", "Owner Name", "Phone", "Email", "F. Manager", "Aciton"];

    const handlePageChange = newPage => {
        console.log(newPage);
        setPage(newPage);
        fetchData({}, newPage);
    };

    if (loading) {
        return <div className='text-4xl mt-[35vh] flex  justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }

    return (
        <div className='mt-5'>
            <div className='flex justify-between items-center'>
                <div>
                    <input type="text" className='input h-10 input-bordered text-sm mr-2'
                        placeholder='Business Name'
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)} />

                    <input type="text" className='input h-10 input-bordered text-sm mr-2'
                        placeholder='Phone'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)} />

                    <input type="text" className='input h-10 input-bordered text-sm mr-2'
                        placeholder='Owner Name'
                        value={ownerName}
                        onChange={(e) => setOwnerName(e.target.value)} />

                    <input type="text" className='input h-10 input-bordered text-sm mr-2'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <button onClick={handleSearch} className='button'>Search</button>
                </div>

                <div>
                    <button onClick={() => (fetchData({}), setEmail(null), setOwnerName(null), setPhone(null), setBusinessName(null))} className="button mr-3">Clear Filter</button>
                    <div className="join">
                        <button className="join-item btn" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>«</button>
                        <button className="join-item btn">{`Page ${page}`}</button>
                        <button className="join-item btn" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>»</button>
                    </div>
                </div>
            </div>
            <div className='mt-3 mb-20 rounded-lg bg-base-200'>
                <div className="overflow-x-auto p-2">
                    <table className="table p-5">
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col">
                                        {
                                            (item === "Funded" && item !== "CREATED") ? (sortByFundedAmount === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByFundedAmount} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByFundedAmountReverse} className='cursor-pointer'><BiSolidUpArrow /></span></p>) : ((item !== "CREATED" && item !== "Req.") && item)
                                        }

                                        {
                                            item === "CREATED" && (sortByDateState === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByDate} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByDateRevarse} className='cursor-pointer'><BiSolidUpArrow /></span></p>)
                                        }

                                        {
                                            item === "Req." && (sortByRequiredAmount === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByRequirements} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByRequirementsReverse} className='cursor-pointer'><BiSolidUpArrow /></span></p>)
                                        }
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {loanList &&
                                loanList.map((item, i) => <tr key={i}>
                                    <td className='font-[500]'>
                                        {item["business-name"]}
                                    </td>
                                    <td className='font-[500]'>
                                        {item["business-owner-name"]}
                                    </td>
                                    <td className='font-[500]'>
                                        {item["business-phone"]}
                                    </td>
                                    <td >
                                        {item["business-email"]}
                                    </td>
                                    <td>
                                        {item["funding-manager"] || 'Pending'}
                                    </td>
                                    <td>
                                        <Link className='btn text-xl px-7 bg-base-300' to={`/admin/new-kanban/edit-deal/${item?._id}`}><FaArrowRightLong /></Link>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>


            {/* popup */}
            <dialog id="editLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <EditLoan item={editLoan} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} />
                </div>
            </dialog>
            <dialog id="loanPdfList" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[500px] max-w-[500px] p-10">
                    <LoanPdfList pdflist={pdflist} handlePdfLinkClick={handlePdfLinkClick} />
                    <button type='button' onClick={() => closeModal("loanPdfList")} className="mt-3 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                </div>
            </dialog>
            <dialog id="viewLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <form method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <ViewLoan item={viewLoan} />
                    <div className="mt-3 text-right">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                    </div>
                </form>
            </dialog>
        </div>
    )
}

export default NewDashboard