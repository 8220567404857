import React, { useContext, useEffect, useState } from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { AuthContext } from '../../context/AuthContext';
import { PacmanLoader } from 'react-spinners';
import axios from 'axios';
import { Link } from 'react-router-dom';

const StaffReview = () => {
    const { user } = useContext(AuthContext);
    const [thisMonthFundedData, setThisMonthFundedData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [qaSummeryData, setQaSummeryData] = useState([]);
    const currentDate = new Date();
    const [isLoading, setIsLoading] = useState(true);
    const [renderCount, setRenderCount] = useState(0);
    const employeeList = user?.email === 'smit@readysteadyfinance.co.uk' ? ['Becher', 'Carl', 'Don', 'Jonnalou', 'Janne'] : ['Jon', 'Millie', 'Steve', 'Smit', 'Mark', 'Becher', 'Carl', 'Don', 'Jonnalou', 'Janne'];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [filterbyMonth, setFilterbyMonth] = useState(currentDate.getMonth());
    const currentMonthName = monthNames[currentDate.getMonth()];

    const getTotalCallsByEmployee = (employee) => {
        return qaSummeryData
            .filter(entry => entry.employee === employee)
            .reduce((total, entry) => total + entry.calls, 0);
    };

    useEffect(() => {
        const monthlySummeryData = monthlyData.filter(entry => {
            const dateObject = new Date(entry.date);
            return dateObject.getMonth() === parseInt(filterbyMonth);
        });
        setQaSummeryData(monthlySummeryData);
    }, [filterbyMonth, monthlyData])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/qa-report`)
            .then(response => response.json())
            .then(data => {
                // Call a function to handle the loan data
                setMonthlyData(data);
                setIsLoading(false); // update loading state
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error or display an error message
            });
    }, [renderCount, filterbyMonth]);

    // Function to fetch data from API
    const fetchFundingData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v3/book/funding-stats`); // Use your API endpoint
            setThisMonthFundedData(response.data);
            console.log(response.data);
        } catch (error) {
            // setError(error);
        }
    };

    // useEffect to fetch data on component mount
    useEffect(() => {
        fetchFundingData();
    }, []);



    if (isLoading) {
        return (
            <div className='font-bold text-4xl flex h-screen justify-center items-center'>
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div>
            <div className="overflow-x-auto border border-base-300 mt-10 p-5 rounded-lg">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th>Staff</th>
                            <th>Latest Score</th>
                            <th>Previous Score</th>
                            <th>Funded Amount this Month</th>
                            <th>Funded Deals this Month</th>
                            <th>Calls this Month</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employeeList.map(eachEmployee => <tr>
                            <td>{eachEmployee}</td>
                            <td>0</td>
                            <td>0</td>
                            <td>£ {
                                thisMonthFundedData.find(item => item.closer === eachEmployee)
                                    ? thisMonthFundedData.find(item => item.closer === eachEmployee).totalFunded.toLocaleString()
                                    : 0
                            }</td>
                            <td>{
                                thisMonthFundedData.find(item => item.closer === eachEmployee)
                                    ? thisMonthFundedData.find(item => item.closer === eachEmployee).totalFundedDeals
                                    : 0
                            }</td>
                            <td>{getTotalCallsByEmployee(eachEmployee).toLocaleString()}</td>
                            <td><Link to={`/admin/single-staff-review/${eachEmployee}`} className='btn text-xl px-7 bg-base-300'><FaArrowRightLong /></Link></td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default StaffReview