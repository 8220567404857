import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import sedgelogo from "./../../assets/sedgelogo.png"

const NewKanbanPDF = ({ loanData, birthDate }) => {
    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    const styles = StyleSheet.create({
        page: {
            // fontFamily: 'Oswald',
            padding: 60,
        },
        topbar: {
            flexDirection: 'row',
            alignItems: 'bottom',
            justifyContent: 'space-between',
            marginBottom: 30,
        },
        topside: {
            flexDirection: 'row',
            alignItems: 'center',
            width: '50%'
        },
        image: {
            width: '80%'
        },
        header: {
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 10,
            marginTop: 10,
        },
        table: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 5,
        },
        cellone: {
            width: '35%',
        },
        celltwo: {
            width: '65%',
        },
        label: {
            fontSize: 14,
            fontWeight: 'bold',
            marginBottom: 5,
            color: '#333',
        },
        title: {
            fontSize: 18,
            fontWeight: 'bold',
            color: '#000',
            marginBottom: 10,
            marginTop: 20
        },
        value: {
            fontSize: 13
        },
    });

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.topbar}>
                    <View style={styles.topside}>
                        <Image style={styles.image} src={sedgelogo} />
                    </View>
                    <View style={styles.topside}>
                        <Text style={styles.header}>Business Loan Application</Text>
                    </View>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.title}>The Business</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Business Name:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-name"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Business Address:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-address"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Ave. Monthly Turnover:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>£{parseFloat(loanData["avarage-monthly-turnover"] || 0).toLocaleString()}</Text>
                        {/* <Text style={styles.value}>£{loanData["business-turnover"]}</Text> */}
                    </View>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.title}>The Owner</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Owner Name:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-owner-name"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>DOB:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{birthDate}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Home Address:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["home-address"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Phone Number:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-phone"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Email:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["business-email"]}</Text>
                    </View>
                </View>
                <View style={styles.cell}>
                    <Text style={styles.title}>The Requirements</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Loan Amount Required:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>£{parseFloat(loanData["required-amount"]).toLocaleString()}</Text>
                        {/* <Text style={styles.value}>£{loanData["required-amount"]}</Text> */}
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Purpose of Loan:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["loan-purpose"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Homeowner / Renting:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["home-owner"]}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.cellone}>
                        <Text style={styles.label}>Underwriting Notes:</Text>
                    </View>
                    <View style={styles.celltwo}>
                        <Text style={styles.value}>{loanData["lender-note"]}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default NewKanbanPDF