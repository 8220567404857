import React, { useContext, useEffect, useRef, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import { Link, useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Confetti from 'react-confetti'
import { AuthContext } from '../../context/AuthContext';
import Popup from '../../components/Popup/Popup';

const ApplyForFunding = () => {
    const form = useRef();
    const [step, setStep] = useState(1);
    const { width, height } = useWindowSize();
    const { createAccount } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const openPopup = () => setIsOpen(true);
    const closePopup = () => setIsOpen(false);


    const generateRandomPassword = (length) => {
        const charset = "abcdefghijklmnopqrstuvw#$#%xyzABCDEFGH%@#$%OPQRSTUVWXYZ0123456789";
        return Array.from({ length }, () => charset[Math.floor(Math.random() * charset.length)]).join('');
    };

    const password = generateRandomPassword(8);

    const [formData, setFormData] = useState({
        "business-name": '',
        "trading-duration": '',
        "business-turnover": '',
        "business-address": '',
        "user": '',
        "cardPaymentsAmount": '',
        "business-phone": '',
        "country": "£",
        "createdDate": new Date().toISOString()
    });

    const [areAllFieldsFilled, setAreAllFieldsFilled] = useState(false);

    const [step1, setStep1] = useState({
        "business-name": '',
        "trading-duration": '',
        "business-turnover": '',
    })
    const [step2, setStep2] = useState({
        "takesCardPayments": '',
        "invoices": '',
        "govermentGrants": '',
    })


    const nextStep = () => {
        setStep(step + 1);
        setAreAllFieldsFilled(false)
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === '') {
            parseInt((25 / 100) * formData["business-turnover"])
        }
        setFormData({
            ...formData,
            [name]: value,
        });
        if (step === 1) {
            setStep1({
                ...step1,
                [name]: value,
            })
            setAreAllFieldsFilled(Object.values(step1).every((fieldValue) => fieldValue.trim() !== ''))
        }
        if (step === 2) {
            setStep2({
                ...step2,
                [name]: value,
            })
            console.log(step2);
            setAreAllFieldsFilled(Object.values({
                ...step2,
                [name]: value,
            }).every((fieldValue) => fieldValue.trim() !== ''))
        }
    };

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        fetch(`${process.env.REACT_APP_SERVER_URL}/user/${formData?.user}`)
            .then(response => response.json())
            .then(result => {
                if (result?._id) {
                    setStep(step + 1);
                    if (parseInt(formData["business-turnover"]) !== 0) {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ ...formData, 'type': 'Business Loan', "required-amount": parseInt((25 / 100) * formData["business-turnover"]) })
                        })
                            .then(response => response.json())
                            .then(result => { })
                            .catch(error => {
                                console.error('Error:', error);
                            });
                    }
                    if (formData?.takesCardPayments === "yes") {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ ...formData, 'type': 'MCA', "required-amount": parseInt((150 / 100) * formData["cardPaymentsAmount"]) })
                        })
                            .then(response => response.json())
                            .then(result => { })
                            .catch(error => {
                                console.error('Error:', error);
                            });
                    }
                    if (formData?.invoiceType === "b2b") {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ ...formData, 'type': 'Invoice', "required-amount": parseInt((40 / 100) * formData["business-turnover"]) })
                        })
                            .then(response => response.json())
                            .then(result => { })
                            .catch(error => {
                                console.error('Error:', error);
                            });
                    }
                    if (formData?.govermentGrants === "no") {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ ...formData, 'type': 'Grants', "required-amount": 8000 })
                        })
                            .then(response => response.json())
                            .then(result => { })
                            .catch(error => {
                                console.error('Error:', error);
                            });
                    }
                    emailjs.send('service_1jzlhir', 'template_6him7ow', { ...formData, password }, '4HTpF49SkKyjYUSGB')
                        .then(function (response) {
                            console.log('SUCCESS!', response.status, response.text);
                        }, function (error) {
                            console.log('FAILED...', error);
                        });
                }
            })
            .catch(error => {
                setStep(step + 1);
                createAccount(formData.user, password)
                    .then(res => {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/update-user`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ email: formData.user, pass: password, "createdDate": new Date().toISOString() })
                        })
                            .then(response => response.json())
                            .then(result => {
                                if (parseInt(formData["business-turnover"]) !== 0) {
                                    fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ ...formData, 'type': 'Business Loan', "required-amount": parseInt((25 / 100) * formData["business-turnover"]) })
                                    })
                                        .then(response => response.json())
                                        .then(result => { })
                                        .catch(error => {
                                            console.error('Error:', error);
                                        });
                                }
                                if (formData?.takesCardPayments === "yes") {
                                    fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ ...formData, 'type': 'MCA', "required-amount": parseInt((150 / 100) * formData["cardPaymentsAmount"]) })
                                    })
                                        .then(response => response.json())
                                        .then(result => { })
                                        .catch(error => {
                                            console.error('Error:', error);
                                        });
                                }
                                if (formData?.invoiceType === "b2b") {
                                    fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ ...formData, 'type': 'Invoice', "required-amount": parseInt((40 / 100) * formData["business-turnover"]) })
                                    })
                                        .then(response => response.json())
                                        .then(result => { })
                                        .catch(error => {
                                            console.error('Error:', error);
                                        });
                                }
                                if (formData?.govermentGrants === "no") {
                                    fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ ...formData, 'type': 'Grants', "required-amount": 8000 })
                                    })
                                        .then(response => response.json())
                                        .then(result => { })
                                        .catch(error => {
                                            console.error('Error:', error);
                                        });
                                }
                            })
                            .catch(error => {
                                console.error('Error:', error);
                                // Handle the error or display an error message
                            });

                    })
                    .catch((error) => {
                        const errorMessage = error.message;
                        console.error(errorMessage);
                    });

                emailjs.send('service_1jzlhir', 'template_cea5lsi', { ...formData, password }, 'pH0fYO6wGMmzTkVH8')
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                    }, function (error) {
                        console.log('FAILED...', error);
                    });
            });

    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    return (
        <div className='min-h-screen'>
            <div className='p-10 pb-0 mt-5 md:mt-0'>
                <img src="https://readysteadyfinance.co.uk/wp-content/uploads/2023/03/Ivory-Black-Luxury-Minimalist-Personal-Name-Logo-12-1.png" className='w-[150px] mb-8 md:absolute' alt="" />
                <div className='text-center text-3xl grow mt-10'>
                    {step === 1 && <h1 className='font-black mb-6 text-[#3e3e3e]'> Lets See What Finance is Available</h1>}
                    {step === 2 && <h1 className='font-black mb-6 text-[#3e3e3e]'> Great, Lets See how much</h1>}
                    {step === 3 && <h1 className='font-black mb-6 text-[#3e3e3e]'> Like these figures? <br /> Lets get your application complete</h1>}
                    {step === 4 && <h1 className='font-black mb-6 text-[#3e3e3e]'> This is the last of the information we need to get going</h1>}
                    {step === 5 && <h1 className='font-black mb-6 text-[#3e3e3e]'> This is the last of the information we need to get going</h1>}
                </div>
            </div>
            <div className='md:min-h-[75vh] flex items-center justify-center flex-col'>

                <form
                    className={`modal-box p-10 md:w-[500px] md:max-w-[500px] rounded-lg ${step === 1 ? 'block' : 'hidden'
                        }`}
                >
                    {/* Step 1: Business Information */}
                    <div>
                        <input
                            type='text'
                            name='business-name'
                            placeholder='Business Name'
                            className='input w-full '
                            required
                            onChange={handleInputChange}
                        />
                        <input
                            type='text'
                            name='trading-duration'
                            placeholder='Length of time trading'
                            className='input  w-full mt-5'
                            required
                            onChange={handleInputChange}
                        />
                        <input
                            type='number'
                            name='business-turnover'
                            placeholder='Annual Turnover'
                            className='input w-full mt-5'
                            required
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className='text-right'>
                        {areAllFieldsFilled && <button type='button' className='button mt-3 disable' onClick={nextStep}>
                            Next
                        </button>}

                    </div>
                </form>

                <form
                    onSubmit={handleSubmit}
                    className={`modal-box p-10 w-[500px] max-w-[500px] rounded-lg ${step === 2 ? 'block' : 'hidden'
                        }`}
                >
                    {/* Step 2: Additional Information */}
                    <div>
                        <div className='col-span-2'>
                            <label className='block text-sm font-medium text-gray-700'>
                                Do you take card payments?
                            </label>
                            <div className='mt-2'>
                                <label className='inline-flex items-center'>
                                    <input
                                        type='radio'
                                        name='takesCardPayments'
                                        value='yes'
                                        className='form-radio'
                                        onChange={handleInputChange}
                                    />
                                    <span className='ml-2'>Yes</span>
                                </label>
                                <label className='inline-flex items-center ml-6'>
                                    <input
                                        type='radio'
                                        name='takesCardPayments'
                                        value='no'
                                        className='form-radio'
                                        onChange={handleInputChange}
                                    />
                                    <span className='ml-2'>No</span>
                                </label>
                            </div>
                        </div>

                        {
                            formData.takesCardPayments === "yes" && <input
                                type='number'
                                name='cardPaymentsAmount'
                                placeholder='Money taken on cards annually'
                                className='input w-full mb-3'
                                required
                                onChange={handleInputChange}
                            />
                        }


                        <div className='col-span-2'>
                            <label className='block text-sm font-medium text-gray-700'>
                                Do you invoice?
                            </label>
                            <div className='mt-2'>
                                <label className='inline-flex items-center'>
                                    <input
                                        type='radio'
                                        name='invoices'
                                        value='yes'
                                        className='form-radio'
                                        onChange={handleInputChange}
                                    />
                                    <span className='ml-2'>Yes</span>
                                </label>
                                <label className='inline-flex items-center ml-6'>
                                    <input
                                        type='radio'
                                        name='invoices'
                                        value='no'
                                        className='form-radio'
                                        onChange={handleInputChange}
                                    />
                                    <span className='ml-2'>No</span>
                                </label>
                            </div>
                        </div>
                        {
                            formData.invoices === "yes" && <div className='col-span-2'>
                                <div className='mt-2'>
                                    <label className='inline-flex items-center'>
                                        <input
                                            type='radio'
                                            name='invoiceType'
                                            value='b2b'
                                            className='form-radio'
                                            onChange={handleInputChange}
                                        />
                                        <span className='ml-2'>B2B</span>
                                    </label>
                                    <label className='inline-flex items-center ml-6'>
                                        <input
                                            type='radio'
                                            name='invoiceType'
                                            value='b2c'
                                            className='form-radio'
                                            onChange={handleInputChange}
                                        />
                                        <span className='ml-2'>B2C</span>
                                    </label>
                                </div>
                            </div>
                        }

                        <div className='col-span-2 mt-3'>
                            <label className='block text-sm font-medium text-gray-700'>
                                Have you had any government grants?
                            </label>
                            <div className='mt-2'>
                                <label className='inline-flex items-center'>
                                    <input
                                        type='radio'
                                        name='govermentGrants'
                                        value='yes'
                                        className='form-radio'
                                        onClick={handleInputChange}
                                    />
                                    <span className='ml-2'>Yes</span>
                                </label>
                                <label className='inline-flex items-center ml-6'>
                                    <input
                                        type='radio'
                                        name='govermentGrants'
                                        value='no'
                                        className='form-radio'
                                        onClick={handleInputChange}
                                    />
                                    <span className='ml-2'>No</span>
                                </label>
                            </div>
                        </div>
                        {
                            formData.govermentGrants === "yes" && <div className='col-span-2'>
                                <div className='mt-2'>
                                    <label className='inline-flex items-center'>
                                        <input
                                            type='radio'
                                            name='govermentGrantsType'
                                            value='startUp'
                                            className='form-radio'
                                            onChange={handleInputChange}
                                        />
                                        <span className='ml-2'>Start Up</span>
                                    </label>
                                    <label className='inline-flex items-center ml-6'>
                                        <input
                                            type='radio'
                                            name='govermentGrantsType'
                                            value='marketing'
                                            className='form-radio'
                                            onChange={handleInputChange}
                                        />
                                        <span className='ml-2'>Marketing</span>
                                    </label>
                                    <label className='inline-flex items-center ml-6'>
                                        <input
                                            type='radio'
                                            name='govermentGrantsType'
                                            value='r&d'
                                            className='form-radio'
                                            onChange={handleInputChange}
                                        />
                                        <span className='ml-2'>R&D</span>
                                    </label>
                                    <label className='inline-flex items-center ml-6'>
                                        <input
                                            type='radio'
                                            name='govermentGrantsType'
                                            value='other'
                                            className='form-radio'
                                            onChange={handleInputChange}
                                        />
                                        <span className='ml-2'>Other</span>
                                    </label>
                                </div>
                            </div>
                        }

                    </div>
                    <div className='flex justify-between mt-5'>
                        <button type='button' className='button mt-3' onClick={prevStep}>
                            Previous
                        </button>

                        {areAllFieldsFilled && <button type='button' className='button mt-3' onClick={nextStep}>
                            Next
                        </button>}
                    </div>
                </form>
                <form
                    onSubmit={handleSubmit}
                    className={`modal-box p-10 w-[500px] max-w-[500px] rounded-lg ${step === 3 ? 'block' : 'hidden'
                        }`}
                >
                    {/* Step 3: Eligibility and Contact Information */}
                    <p className='mb-2 text-lg font-semibold text-center text-[#585858]'>
                        Based on the information provided you are eligible to the following -
                    </p>
                    <ul>
                        <li>{parseInt(formData["business-turnover"]) !== 0 && `- £${parseInt((25 / 100) * formData["business-turnover"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })} Business Loan`} </li>
                        <li>{formData?.takesCardPayments === "yes" && `- £${parseInt((150 / 100) * formData["cardPaymentsAmount"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })} MCA`}</li>
                        <li>{formData?.invoiceType === "b2b" && `- £${parseInt((40 / 100) * formData["business-turnover"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })} Invoice Finance`}</li>
                        <li>{formData?.govermentGrants === "no" && `- Minimum £8,000 Grant`}</li>
                    </ul>
                    <div>
                    </div>
                    <div className='flex justify-between mt-5'>
                        <button type='button' className='button mt-3' onClick={prevStep}>
                            Previous
                        </button>
                        <button type='button' className='button mt-3' onClick={nextStep}>
                            Next
                        </button>
                    </div>
                </form>

                <form
                    onSubmit={handleSubmit}
                    className={`modal-box p-10 w-[500px] max-w-[500px] rounded-lg ${step === 4 ? 'block' : 'hidden'
                        }`}
                >
                    {/* Step 4: Final Information */}
                    <div>
                        <p className='mb-2 text-xl font-semibold text-center text-[#585858]'>
                            Personal Information
                        </p>
                        <input
                            name='contactName'
                            type="text"
                            placeholder="Contact Name"
                            className="input w-full mt-5"
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type='text'
                            name='business-address'
                            placeholder='Home address'
                            className='input  w-full mt-3'
                            required
                            onChange={handleInputChange}
                        />
                        <input
                            type='text'
                            name='business-phone'
                            placeholder='Phone'
                            className='input  w-full mt-3'
                            required
                            onChange={handleInputChange}
                        />
                        <input
                            type='text'
                            name='user'
                            placeholder='Email Address'
                            className='input w-full mt-3'
                            required
                            onChange={handleInputChange}
                        />
                        <label className='block font-medium text-gray-700 mt-5'>
                            Date of Birth
                        </label>
                        <input
                            type='date'
                            name='dateOfBirth'
                            placeholder='Date of Birth'
                            className='input w-full mt-1'
                            required
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='flex justify-between mt-5'>
                        <button type='button' className='button mt-3' onClick={prevStep}>
                            Previous
                        </button>
                        <button type='button' className='button mt-3' onClick={nextStep}>
                            Next
                        </button>
                    </div>
                </form>
                <form
                    onSubmit={handleSubmit}
                    className={`modal-box p-10 w-[500px] max-w-[500px] rounded-lg ${step === 5 ? 'block' : 'hidden'
                        }`}
                >
                    {/* Step 4: Final Information */}
                    <div>
                        <p className='mb-2 text-xl font-semibold text-center text-[#585858]'>
                            Final Information
                        </p>
                        <label className='mt-5 block text-sm'>Are You A Home Owner?</label>
                        <input
                            name='homeOwner'
                            type="text"
                            className="input w-full"
                            onChange={handleInputChange}
                            required
                        />
                        <label className='mt-3 block text-sm'>How Long At Current Address?</label>
                        <input
                            type='text'
                            name='timeAtCurrentAddress'
                            className='input  w-full'
                            required
                            onChange={handleInputChange}
                        />
                        <label className='mt-3 block text-sm'>Have You Engaged Another Finance Broker In The Past Three Months?</label>
                        <input
                            type='text'
                            name='pastThreeMonthsEngagedInformation'
                            className='input  w-full'
                            required
                            onChange={handleInputChange}
                        />
                        <label className='mt-3 block text-sm'>Do You Know Of Any Adverse Information on Your Credit File?</label>
                        <input
                            type='text'
                            name='creditCardAdverseInfo'
                            className='input w-full'
                            required
                            onChange={handleInputChange}
                        />
                        <label className='mt-3 block text-sm'>Is Your Business Able To Afford Loan Repayments?</label>
                        <input
                            type='text'
                            name='loanRepamentAfford'
                            className='input w-full'
                            required
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className='text-sm flex gap-2 mt-3'>
                        <input
                            type="checkbox"
                            className="checkbox checkbox-sm"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <p>
                            By clicking this checkbox, you agree to our Terms and Conditions. <span onClick={openPopup} className='color-rsf cursor-pointer'>Terms & Conditions</span>
                        </p>

                    </div>
                    <div className='flex justify-between mt-5'>
                        <button type='button' className='button mt-3' onClick={prevStep}>
                            Previous
                        </button>
                        {
                            loading ? <button className="btn">
                                <span className="loading loading-spinner"></span>
                                loading
                            </button> : <button type='submit' className={`button mt-3 ${!isChecked ? "!bg-[#ff998e]" : ""}`} disabled={!isChecked}>
                                Submit
                            </button>
                        }

                    </div>
                </form>
                <div
                    className={`modal-box p-10 w-[800px] max-w-[800px] z-[10] rounded-lg ${step === 6 ? 'block' : 'hidden'
                        }`}
                >
                    {/* Step 4: Final Information */}
                    <h1 className='text-3xl font-black text-center text-[#e63a27]'>Thank you for submitting your application</h1>
                    <p className='mt-3 text-center'>One of our funding managers will be in contact shortly to discuss your application.</p>
                    <p className='mt-5 font-bold text-center text-[#e63a27]'>Login details have been sent to your e-mail so you can view live progress on your applications.</p>
                </div>
                {step === 6 && <Confetti
                    numberOfPieces={150}
                    recycle={true}
                    width={width}
                    height={height}
                />}
            </div>
            <Popup isOpen={isOpen} onClose={closePopup}>
                <h2 className="text-2xl font-bold mb-3">Terms and Condition</h2>
                <p className='text-sm'>I hereby confirm that my personal information provided to Sedge Funding Ltd t/as Ready Steady Finance, will
                    be used in accordance with the Privacy Policy of Sedge Funding Ltd, including searches at credit reference
                    agencies to verify the details in this application and for credit assessments. Sedge funding also has the right to call and email you for the next 5 years, you also give permission for sedge funding ltd to keep all information and documents safe and share with other lenders.</p>
            </Popup>
        </div>

    );
}

export default ApplyForFunding