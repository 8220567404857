import React, { useContext, useState } from 'react'
import { ref, uploadBytes } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';
import { handleAdminRoute } from '../../functions/functions';

const Mca = () => {
    const { setCountry, country, user, adminData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [allpdf, setAllpdf] = useState([]);
    const [loading, setLoading] = useState(false);


    const submitLoanRequest = (e) => {
        e.preventDefault()
        setLoading(true);
        const loanData = {
            "business-type": e?.target?.businessType?.value,
            "business-name": e?.target?.businessName?.value,
            "business-contact-name": e?.target?.contactName?.value,
            "business-phone": e?.target?.phone?.value,
            "business-email": e?.target?.email?.value,
            "business-address": e?.target?.businessAddress?.value,
            "business-chip": e?.target?.chipPin?.value,
            "business-turnover": e?.target?.businessTurnover?.value,
            "business-invoice": e?.target?.invoice?.value,
            "business-notes": e?.target?.notes?.value,
            "required-amount": e?.target?.requiredAmount?.value,
            "funding-manager": user?.displayName || "Pending",
            "funded-amount": "0",
            "user": user?.email,
            "seenby": [],
            "updated": true,
            "status": 'Pre-Underwriting',
            "type": "MCA",
            "country": country,
            "createdDate": new Date().toISOString()
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loanData)
        })
            .then(response => response.json())
            .then(result => {
                // upload pdf with inserted id
                if (allpdf.length !== 0) {
                    const files = allpdf;
                    const fileUploadPromises = [];
                    for (const file of files) {
                        const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                        const uploadPromise = uploadBytes(pdfREF, file);
                        fileUploadPromises.push(uploadPromise);
                    }
                    setAllpdf([]);
                    setLoading(false);
                }
                e.target.reset();
                setLoading(false);
                navigate(`${handleAdminRoute(adminData)}`);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    return (
        <div>
            <Link to={`${handleAdminRoute(adminData)}/request-loan`} className='btn mt-5 bg-white text-gray-500' style={{ "boxShadow": "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}><AiOutlineArrowLeft />Go Back</Link>
            <form onSubmit={(e) => submitLoanRequest(e)} className="mt-8 mr-10 mx-auto">
                <div className='grid grid-cols-3 gap-3'>
                    <div>
                        <label htmlFor="country" className='text-gray-500 text-sm mb-1 block'>Set Country:</label>
                        <select onChange={(e) => setCountry(e.target.value)} id='country' name='country' className="input w-full">
                            <option value="£" defaultValue>£ - United Kingdom</option>
                            <option value="$" >$ - USA</option>
                            <option value="€" >€ - Belgium</option>
                            <option value="€" >€ - Germany</option>
                            <option value="€" >€ - Ireland</option>
                            <option value="€" >€ - Netherlands</option>
                            <option value="€" >€ - Poland</option>
                            <option value="€" >€ - Spain</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="businessType" className='text-gray-500 text-sm mb-1 block'>Business Type:</label>
                        <select name='businessType' id='businessType' className="input w-full" required>
                            <option value="" defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="businessName" className='text-gray-500 text-sm mb-1 block'>Business Name:</label>
                        <input name='businessName' id='businessName' type="text" placeholder="Business Name" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="contactName" className='text-gray-500 text-sm mb-1 block'>Contact Name:</label>
                        <input name='contactName' type="text" placeholder="Contact Name" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="phone" className='text-gray-500 text-sm mb-1 block'>Phone Number:</label>
                        <input name='phone' id='phone' type="text" placeholder="Phone Number" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="email" className='text-gray-500 text-sm mb-1 block'>Email:</label>
                        <input name='email' id='email' type="text" placeholder="Email" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="businessAddress" className='text-gray-500 text-sm mb-1 block'>Business Address:</label>
                        <input name='businessAddress' id='businessAddress' type="text" placeholder="Business Address" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="chipPin" className='text-gray-500 text-sm mb-1 block'>Chip & Pin monthly:</label>
                        <input name='chipPin' id='chipPin' type="text" placeholder="Chip & Pin monthly" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="businessTurnover" className='text-gray-500 text-sm mb-1 block'>Business Turnover Monthly:</label>
                        <input name='businessTurnover' id='businessTurnover' type="text" placeholder="Business Turnover Monthly" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="invoice" className='text-gray-500 text-sm mb-1 block'>Do they Invoice:</label>
                        <input name='invoice' id='invoice' type="text" placeholder="Do they Invoice" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="requiredAmount" className='text-gray-500 text-sm mb-1 block'>Required Amount:</label>
                        <input name='requiredAmount' id='requiredAmount' type="text" placeholder="Required Amount" className="input w-full" required />
                    </div>
                    <div>
                        <label htmlFor="notes" className='text-gray-500 text-sm mb-1 block'>Notes</label>
                        <input name='notes' type="text" placeholder="Notes" className="input w-full" />
                    </div>
                </div>
                <div className='mt-4 text-right'>
                    {
                        loading ? <button className="btn">
                            <span className="loading loading-spinner"></span>
                            loading
                        </button> : <button type='submit' className='button !rounded'>submit</button>
                    }
                </div>
            </form>
        </div>
    )
}

export default Mca
