import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import { AiOutlineArrowLeft, AiOutlineEye } from 'react-icons/ai';
import { AuthContext } from '../../context/AuthContext';
import uploadIcon from "./../../assets/upload.svg";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PacmanLoader } from 'react-spinners';
import axios from 'axios';
import useIban from '../../hooks/useIban';
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import NewKanbanPDF from '../../pdf/NewKanbanPDF/NewKanbanPDF';

const statusArray = ['Initial Review', 'Golden Data', 'The Great Takeover', 'Pre-Underwriting', 'Pre-Offer', 'Underwriting', 'Extra docs ob', 'Awaiting Offer', 'Offer', 'Contract Sent', 'Awaiting Funding', 'Last Chance', 'Cancelled', 'Cancelled for ever']

const EditSingleDeal = () => {
    const { user, adminData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [teamNote, setTeamNote] = useState('');
    const [renewLoanData, setRenewLoanData] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [bankToken, setBankToken] = useState();
    const [openBankingRequisition, setOpenBankingRequisition] = useState(null);
    const [uploadingLoader, setUploadingLoader] = useState(false);
    const [tempUpdateValue, setTempUpdateValue] = useState({});
    const [dataError, setDataError] = useState(false);
    const [totalStaffComs, settotalStaffComs] = useState(0);
    const [agreementData, setAgreementData] = useState({});
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [activeLender, setActiveLender] = useState(searchParams.get("lender") || 'New Deal');
    const [loading, setLoading] = useState(true);
    const [loanData, setLoanData] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [statusStep, setStatusStep] = useState(1);
    const [showFundingHistory, setShowFundingHistory] = useState(false);
    const lenderArray = [
        'New Deal',
        "YouLend",
        "365 Finance",
        "IWOCA",
        "Funding Circle",
        "Funding Alternative",
        "Swiftfund",
        "Maxcap",
        "Bizcap",
        "Capify",
        "Playter",
        "Bibby",
        "Penny"
    ];

    const lendersRenewalPeriods = {
        Youlend: 3,
        '365 Finance': 6,
        Liberis: 6,
        IWOCA: 10,
        'Funding Circle': 12,
        'Funding Alternative': 3,
        Swiftfund: 3,
        Maxcap: 6,
        Bizcap: 2,
        Capify: 6
    };


    // Final Functions
    const [status, setStatus] = useState(null);
    const [fundingManager, setFundingManager] = useState(null);
    const [showInKanban, setShowInKanban] = useState(null);
    const [businessName, setBusinessName] = useState(null);
    const [businessType, setBusinessType] = useState(null);
    const [businessAddress, setBusinessAddress] = useState(null);
    const [businessOwnerName, setBusinessOwnerName] = useState(null);
    const [birthDate, setBirthDate] = useState(null);
    const [homeAddress, setHomeAddress] = useState(null);
    const [businessEmail, setBusinessEmail] = useState(null);
    const [businessPhone, setBusinessPhone] = useState(null);
    const [homeOwner, setHomeOwner] = useState(null);
    const [avarageMonthlyTurnover, setAvarageMonthlyTurnover] = useState(null);
    const [invoiceAmount, setInvoiceAmount] = useState(null);
    const [marchentTurnover, setMarchentTurnover] = useState(null);
    const [requiredAmount, setRequiredAmount] = useState(null);
    const [overdraftLimit, setOverdraftLimit] = useState(null);
    const [loanPurpose, setLoanPurpose] = useState(null);
    const [lenderNote, setLenderNote] = useState(null);
    const [offerAmount, setOfferAmount] = useState(null);
    const [offerRepayment, setOfferRepayment] = useState(null);
    const [companyComs, setCompanyComs] = useState(null);
    const [staffComs, setStaffComs] = useState(null);
    const [offerNotes, setOfferNotes] = useState(null);
    const [declineNotes, setDeclineNotes] = useState(null);



    // State for funding-history
    const [dateFunded, setDateFunded] = useState('');
    const [funder, setFunder] = useState('');
    const [loanAmount, setLoanAmount] = useState('');
    const [repaymentAmount, setRepaymentAmount] = useState('');



    //on input field change
    const [offerAmountLive, setOfferAmountLive] = useState('0');
    const [offerRepaymentLive, setOfferRepaymentLive] = useState('0');
    const [companyComsLive, setCompanyComsLive] = useState('0');
    const [offerNotesLive, setOfferNotesLive] = useState('');
    const [declineNotesLive, setDeclineNotesLive] = useState('');

    const changeLenderValues = (lender) => {
        setOfferAmountLive(loanData?.lenders?.[lender]?.['offer-amount'] || '0');
        setOfferRepaymentLive(loanData?.lenders?.[lender]?.['offer-repayment'] || '0');
        setCompanyComsLive(loanData?.lenders?.[lender]?.['company-coms'] || '0');
        setOfferNotesLive(loanData?.lenders?.[lender]?.['offer-notes'] || '');
        setDeclineNotesLive(loanData?.lenders?.[lender]?.['decline-notes'] || '');

        // set all the previous to null: 
        setStatus(null);
        setFundingManager(null);
        setShowInKanban(null);
        setBusinessName(null);
        setBusinessType(null);
        setBusinessAddress(null);
        setBusinessOwnerName(null);
        setBirthDate(null);
        setHomeAddress(null);
        setBusinessEmail(null);
        setBusinessPhone(null);
        setHomeOwner(null);
        setAvarageMonthlyTurnover(null);
        setInvoiceAmount(null);
        setMarchentTurnover(null);
        setRequiredAmount(null);
        setOverdraftLimit(null);
        setLoanPurpose(null);
        setLenderNote(null);
        setOfferAmount(null);
        setOfferRepayment(null);
        setCompanyComs(null);
    }

    // Function to handle form submission
    const handleFundingHistorySave = async () => {
        // Construct funding history object
        const fundingHistory = {
            date: new Date(dateFunded),
            funder,
            'remaining': repaymentAmount,
            'loan-amount': loanAmount,
            'repayment-amount': repaymentAmount,
        };

        // Make sure all fields are filled before making the API call
        if (!dateFunded || !funder || !loanAmount || !repaymentAmount) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            // Call the backend API to save funding history
            const response = await axios.put(
                `${process.env.REACT_APP_SERVER_URL}/api/v3/update/deal/${id}/funding-history`,
                {
                    fundingHistory
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token is stored in localStorage
                    }
                }
            );
            console.log('API Response:', response.data);
            fetchSingleDealData();
        } catch (error) {
            console.error('Error updating funding history:', error);
            alert('There was an error updating the funding history.');
        }
    };

    const calculateRenewalDate = (lender, fundedDate) => {
        const fundingHistory = loanData?.['funding-history'];
        const renewalMonths = lendersRenewalPeriods[lender];

        if (!renewalMonths) {
            return 'N/A'; // Handle invalid lender case
        }

        // Find the funding history for the current lender
        const lenderHistory = fundingHistory.filter(entry => entry.funder === lender);

        // Sort lender's funding history by date to check for renewals
        lenderHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

        // If the current date is the latest date for this lender, return the renewal date
        const latestFundingDate = new Date(lenderHistory[0].date); // Most recent funding date
        const currentFundingDate = new Date(fundedDate);

        if (currentFundingDate.getTime() === latestFundingDate.getTime()) {
            // Calculate the renewal date from the provided fundedDate
            currentFundingDate.setMonth(currentFundingDate.getMonth() + renewalMonths);
            return currentFundingDate.toLocaleDateString('en-GB'); // Return calculated renewal date
        } else {
            // If this is not the latest date, it means it has already been renewed
            return 'Done';
        }
    };

    const dealEditUpdate = async (goback) => {



        const activeLenderStatus = loanData?.lenders[activeLender]?.status;
        console.log(!activeLenderStatus);
        console.log(!status);
        if (!status && !activeLenderStatus) {
            alert('Select a status please!');
            return;
        }

        setLoading(true);

        // Prepare the data object for the API request
        const data = {
            lender: activeLender, // Always include activeLender
            ...(status && { status }), // Add only if `status` is truthy
            ...(fundingManager && { 'funding-manager': fundingManager }), // Add only if `fundingManager` is truthy
            ...(showInKanban && { 'show-in-kanban': showInKanban }),
            ...(businessName && { 'business-name': businessName }),
            ...(businessType && { 'business-type': businessType }),
            ...(businessAddress && { "business-address": businessAddress }),
            ...(businessOwnerName && { "business-owner-name": businessOwnerName }),
            ...(birthDate && { "birth-date": birthDate }),
            ...(homeAddress && { 'home-address': homeAddress }),
            ...(businessEmail && { 'business-email': businessEmail }),
            ...(businessPhone && { 'business-phone': businessPhone }),
            ...(homeOwner && { 'home-owner': homeOwner }),
            ...(avarageMonthlyTurnover && { 'avarage-monthly-turnover': avarageMonthlyTurnover }),
            ...(invoiceAmount && { 'invoice-amount': invoiceAmount }),
            ...(marchentTurnover && { 'marchent-turnover': marchentTurnover }),
            ...(requiredAmount && { 'required-amount': requiredAmount }),
            ...(overdraftLimit && { 'overdraft-limit': overdraftLimit }),
            ...(loanPurpose && { 'loan-purpose': loanPurpose }),
            ...(lenderNote && { 'lender-note': lenderNote }),
            ...(offerAmount && { 'offer-amount': offerAmount }),
            ...(offerRepayment && { 'offer-repayment': offerRepayment }),
            ...(companyComs && { 'company-coms': companyComs }),
            ...(offerNotes && { 'offer-notes': offerNotes }),
            ...(declineNotes && { 'decline-notes': declineNotes }),
        };


        // Replace these variables with actual values
        const loanRequestId = id; // Get the actual loan request ID
        const userEmail = user?.email; // Get the actual user email

        try {
            // Call the API only if there is data to update
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/v3/update/loan-request/${loanRequestId}`, {
                email: userEmail, // Include the email
                data: data, // Include the deal data
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}` // Add token from localStorage
                }
            });

            if (goback === true && response.data.message === 'Update successful and history recorded') {
                navigate(-1)
            }

            if (goback === false && response.data.message === 'Update successful and history recorded') {
                fetchSingleDealData();
            }
        } catch (error) {
            setLoading(false);
            console.error('Error updating deal:', error);
            alert('There was an error updating the deal. Contact Steav and give error code: #9GH86');
        }
    };

    // --------------------------------------------------------

    const fetchSingleDealData = () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/single-deal/${id}`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: user?.email })
        })
            .then(response => response.json())
            .then(data => {
                setLoanData(data);
                getTotalStaffComs(data);
                setOfferAmountLive(data?.lenders?.[activeLender]?.['offer-amount'] || '0');
                setOfferRepaymentLive(data?.lenders?.[activeLender]?.['offer-repayment'] || '0');
                setCompanyComsLive(data?.lenders?.[activeLender]?.['company-coms'] || '0');
                setOfferNotesLive(data?.lenders?.[activeLender]?.['offer-notes'] || '');
                setDeclineNotesLive(data?.lenders?.[activeLender]?.['decline-notes'] || '');
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    }

    useEffect(() => {
        if ((loanData?.lenders?.[activeLender]?.status || 'Initial Review') === "Initial Review") {
            setStatusStep(1);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Additional Documents") {
            setStatusStep(2);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Golden Data") {
            setStatusStep(3);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "The Great Takeover") {
            setStatusStep(4);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Pre-Underwriting") {
            setStatusStep(5);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Pre-Offer") {
            setStatusStep(6);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Underwriting") {
            setStatusStep(7);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Extra docs ob") {
            setStatusStep(8);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Offer") {
            setStatusStep(9);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Awaiting Offer") {
            setStatusStep(10);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Contract Sent") {
            setStatusStep(11);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Awaiting Funding") {
            setStatusStep(12);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Funded This Month") {
            setStatusStep(13);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Funded") {
            setStatusStep(14);
        }
        else if (loanData?.lenders?.[activeLender]?.status === "Cancelled") {
            setStatusStep(15);
        }
    }, [activeLender, loanData])


    useEffect(() => {
        setLoading(true);
        fetchSingleDealData();
    }, [id, refresh, user?.email])

    // -------------------------------------------------------------------------

    const addToBook = () => {
        const bookInfo = {
            "funder": loanData?.type,
            "companyname": loanData["business-name"],
            "loanamount": loanData["required-amount"],
            "repayment": loanData["funded-amount"],
            "factor": 0,
            "comrate": 0,
            "comamo": loanData['company-coms'],
            "status": loanData?.status,
            "closer": loanData["funding-manager"],
            "note": "",
            "user": user.email,
            "createdDate": new Date().toISOString()
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/submit-deals`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bookInfo)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleCallBackSubmit = async () => {
        // console.log(selectedDate, selectedTime, dateTime);s
        if (!selectedDate || !selectedTime) {
            return;
        }

        try {
            // Make API call here using axios
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/add-callback/${loanData._id}`, { callBack: { date: selectedDate, time: selectedTime } }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.modifiedCount) {
                setLoading(true);
                fetchSingleDealData();
            }
            console.log('Response:', response?.data);
            // Handle response as needed
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleWheel = (e) => {
        e.preventDefault(); // Prevent scrolling
        e.target.blur();    // Remove focus to disable scroll change
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault(); // Prevent arrow key changes
        }
    };

    //custom hook
    const { accountData, obloading } = useIban(openBankingRequisition?.accounts);




    const [formData, setFormData] = useState({
        companyComs: loanData["company-coms"] || '',
        staffComs: loanData["staff-coms"] || '',
    });

    const handleCompanyComsChange = (event) => {
        const companyComsValue = event.target.value;
        setFormData({
            ...formData,
            companyComs: companyComsValue,
            staffComs: calculateStaffComs(companyComsValue),
        });
        setTempUpdateValue(prev => ({
            ...prev,
            'company-coms': companyComsValue,
            'staff-coms': calculateStaffComs(companyComsValue),
        }));
    };

    const calculateStaffComs = (companyComsValue) => {
        return (parseFloat(companyComsValue) * 0.01).toFixed(2);
    };

    useEffect(() => {
        setRenewLoanData({ ...loanData });
        fetch(`${process.env.REACT_APP_SERVER_URL}/get-loan-edit-history/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: user.email })
        })
            .then(response => response.json())
            .then(data => {
                setHistory(data.history.reverse());
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [loanData, user])

    // Function to handle checkbox change
    const handleCheckboxChange = (e, field, subField) => {
        const isChecked = e.target.checked;
        // const updatedLoanData = { ...loanData };

        // Call the new API endpoint to update the checkbox status dynamically
        fetch(`${process.env.REACT_APP_SERVER_URL}/update-checkbox/${loanData._id}/${field}/${subField}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ updatedValue: isChecked })
        })
            .then(response => response.json())
            .then(result => {
                // Handle the result if needed
                console.log(result);
            })
            .catch(error => {
                console.error('Error:', error);
            });

        // Update the state locally
        // updatedLoanData.documents[field][subField] = isChecked;
        setRefresh(!refresh);
    };

    const handleNoteSubmit = () => {
        // const note = user["displayName"] + " - " + teamNote
        const userDisplayName = user["displayName"];
        const currentTime = new Date();
        const options = { timeZone: "Europe/London", year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedTime = currentTime.toLocaleString("en-GB", options);

        const note = {
            name: `${userDisplayName} - ${formattedTime}`,
            note: teamNote
            // other properties of the note object...
        };
        if (teamNote.length !== 0) {
            fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/add-note/${loanData?._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ lenders: { [activeLender]: { 'team-notes': note } } }),
                body: JSON.stringify({
                    lenderName: activeLender,
                    note
                }),
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        // Handle success, maybe update UI or show a notification
                        console.log(result.message);
                        setRefresh(!refresh)
                        setTeamNote(''); // Clear the input after submission
                    } else {
                        // Handle failure, maybe show an error message
                        console.error(result.message);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const handleFileChange = async (e, field) => {
        setUploadingLoader(true);
        const files = e.target.files;
        if (!files || files.length === 0) return;
        console.log(field);
        try {
            const attachment = loanData?.documents?.[field]?.attachment;
            let downloadURLs;

            if (Array.isArray(attachment)) {
                // If it's an array, create a copy of the array
                downloadURLs = [...attachment];
            } else if (typeof attachment === 'string') {
                // If it's a string, create an array with the string as its only element
                downloadURLs = [attachment];
            } else {
                // Handle other cases as needed
                downloadURLs = [];
            }

            for (const file of files) {
                const pdfREF = ref(storage, `pdf/${loanData?._id}/${file.name}`);

                await uploadBytes(pdfREF, file);

                const downloadURL = await getDownloadURL(pdfREF);
                downloadURLs.push({ url: downloadURL, name: file.name });
            }

            const pdfUPLOAD = {
                "attachment": downloadURLs
            };

            fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/add-document/${loanData._id}/${field}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ updatedValue: pdfUPLOAD })
            })
                .then(response => response.json())
                .then(result => {
                    setRefresh(!refresh);
                    setUploadingLoader(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    // Function to handle date change
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Function to handle time change
    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    // Function to generate time slots
    const generateTimeSlots = () => {
        const timeSlots = [];
        const startTime = 8 * 60; // 8:00 AM in minutes
        const endTime = 20 * 60; // 8:00 PM in minutes
        const interval = 15; // 15 minutes interval

        for (let i = startTime; i < endTime; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const displayHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedTime = `${displayHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
            timeSlots.push(formattedTime);
        }

        return timeSlots;
    };

    const copyToClipboard = () => {
        const link = `${process.env.REACT_APP_OPEN_BANKING + '/' + loanData._id}`;
        navigator.clipboard.writeText(link).then(() => {
            alert('Link copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const fetchAllRequisitions = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/gocardless-token`;
        try {
            const response = await fetch(url, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();

            try {
                setLoading(true);
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/gocardless-requisitions/${loanData['open-banking-ref']}`, {
                    token: responseData?.access
                });
                setOpenBankingRequisition(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }

            localStorage.setItem('bankToken', responseData?.access)
            setBankToken(responseData?.access);
        } catch (error) {
            console.error('Error:', error);
        }

    };

    useEffect(() => {
        if (loanData['open-banking-ref']) {
            fetchAllRequisitions();
        }
    }, [])

    const exportData = async () => {
        const accountId = document.getElementById('openBankingAccountID').value;
        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/gocardless-account-transactions/${accountId}`, {
                token: bankToken
            });

            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(response.data)
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = `${loanData['business-name']}.json`;

            link.click();

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const generateFileName = () => {
        // Get the business name, convert it to lower case, and replace spaces with underscores
        const formattedBusinessName = loanData["business-name"]
            .toLowerCase()
            .replace(/\s+/g, '_');

        const date = new Date().toLocaleDateString('en-GB').replace(/\//g, '-');
        return `${formattedBusinessName}_${date}.pdf`;
    };

    const handleChangedValue = (e) => {
        setTempUpdateValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    const newUpdateFunction = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Insert your JWT token here
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/update/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: user.email,
                    data: tempUpdateValue,
                    _id: loanData._id
                })
            });

            if (!response.ok) {
                setDataError(true);
                setLoading(false);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            setLoading(false);
            setRefresh(!refresh);
        } catch (error) {
            setDataError(true);
            console.error('Error:', error);
        }
    }

    const newUpdateFunctionBack = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Insert your JWT token here
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/update/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: user.email,
                    data: tempUpdateValue,
                    _id: loanData._id
                })
            });

            if (!response.ok) {
                setLoading(false);
                setDataError(true);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            setLoading(false);
            setRefresh(!refresh);
            navigate(-1)
        } catch (error) {
            setDataError(true);
            console.error('Error:', error);
        }
    }

    const getTotalStaffComs = (data) => {
        const staffComs = (parseFloat(data.lenders[activeLender]?.["company-coms"]) * 0.01).toFixed(2);

        if (adminData.role === 'super-admin') {
            if (data['funding-manager'] === 'Jon') {
                settotalStaffComs((4.5 * staffComs));
            } else if (data['funding-manager'] === 'Millie') {
                settotalStaffComs(4 * staffComs);
            } else if (data['funding-manager'] === 'Smit') {
                settotalStaffComs(4.5 * staffComs);
            } else if (data['funding-manager'] === 'Mark') {
                settotalStaffComs(4 * staffComs);
            } else {
                settotalStaffComs(4 * staffComs);
            }
        }
    }

    // useEffect(() => {
    //     getTotalStaffComs();
    // }, [])

    const [editingIndex, setEditingIndex] = useState(null);
    const [fundingEstRemaining, setFundingEstRemaining] = useState(null);
    const [fundingLoanAmount, setFundingLoanAmount] = useState(null);
    const [fundingFunder, setFundingFunder] = useState(null);
    const [fundingRepayment, setFundingRepayment] = useState(null);
    const [fundingDate, setFundingDate] = useState(null);
    const saveChanges = async (currentItem) => {
        // Prepare data from inputs, only adding to the object if the value is not null
        let updatedData = {};
        if (fundingEstRemaining !== null) {
            updatedData.remaining = fundingEstRemaining;
        } else {
            updatedData.remaining = currentItem.remaining
        }
        if (fundingLoanAmount !== null) {
            updatedData.loanAmount = fundingLoanAmount;
        } else {
            updatedData.loanAmount = currentItem['loan-amount']
        }
        if (fundingRepayment !== null) {
            updatedData.repaymentAmount = fundingRepayment;
        } else {
            updatedData.repaymentAmount = currentItem['repayment-amount']
        }
        if (fundingFunder !== null) {
            updatedData.funder = fundingFunder;
        } else {
            updatedData.funder = currentItem['funder']
        }
        if (fundingDate !== null) {
            updatedData.date = fundingDate;
        } else {
            updatedData.date = currentItem['date']
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/v3/funding-history-update`, {
                id: loanData._id,  // Make sure to have access to the item's ID
                item: currentItem,
                updates: updatedData
            });
            if (response.status === 200) {
                setEditingIndex(null);
                setFundingEstRemaining(null);
                setFundingLoanAmount(null);
                setFundingRepayment(null);
                fetchSingleDealData();
            }
        } catch (error) {
            console.error('Error updating funding history:', error);
        }
    };

    const deleteFundingHistoryEntry = async (itemToDelete) => {
        itemToDelete.date = new Date(itemToDelete.date);
        try {
            console.log(`${process.env.REACT_APP_SERVER_URL}/api/v3/funding-history-delete`);
            const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/v3/funding-history-delete`, {
                data: { id: loanData?._id, itemToDelete }
            });
            if (response.status === 200) {
                fetchSingleDealData();
            }
        } catch (error) {
            console.error('Error deleting funding history entry:', error);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    };

    const deleteDeal = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/v3/deal-delete`, {
                data: { id } // Pass the id in the request body
            });

            if (response.status === 200) {
                alert('Deal successfully deleted');
                navigate('/admin/new-kanban')
                // Add any additional actions after the deletion, such as refreshing the list
            } else {
                alert('Failed to delete the deal');
            }
        } catch (error) {
            console.error('Error deleting the deal:', error);
            alert('Error occurred while deleting the deal');
        }
    };



    if (loading) {
        return <div className='text-4xl mt-[35vh] flex  justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }

    return (
        <div>
            <div>
                {
                    dataError && <div role="alert" className="alert alert-error mt-5">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Error! Something Went Wrong, Please try again or contact Steav.</span>
                    </div>
                }
                <div className='mt-5 flex gap-3 justify-between'>
                    <div className='flex gap-3'>
                        <Link to="#" onClick={() => navigate(-1)} className='btn bg-white hover:text-white hover:bg-primary text-gray-500'><AiOutlineArrowLeft />Go Back</Link>
                        <PDFDownloadLink fileName={generateFileName()} document={<NewKanbanPDF loanData={loanData} birthDate={new Date(loanData['birth-date']).toLocaleDateString("en-GB")}></NewKanbanPDF>} className='btn bg-white hover:text-white hover:bg-primary text-gray-500'>PDF</PDFDownloadLink>
                    </div>
                    {
                        adminData?.role === "super-admin" && <div className='flex items-center'>
                            <p className="text-lg font-bold">Total Staff Coms: £{parseInt(totalStaffComs).toLocaleString('en-us') || 0}</p>
                        </div>
                    }
                    <div>
                        <div className='flex gap-3'>
                            {/* <button onClick={handleAgreementCopy} className='btn'>Copy Agreement Link</button>
                            {agreementData?.signature && <PDFDownloadLink fileName={generateFileName()} document={<Agreement1 clientName={agreementData?.clientName} businessName={agreementData?.businessName} businessAddress={agreementData?.businessAddress} signature={agreementData?.signature} fileName="Loan Data"></Agreement1>} className='btn'>Download Agreement</PDFDownloadLink>} */}
                            {adminData?.role === 'super-admin' && (
                                <button
                                    className='btn'
                                    onClick={() => {
                                        const confirmDelete = window.confirm("Are you sure you want to delete this deal?");
                                        if (confirmDelete) {
                                            deleteDeal(id);
                                        }
                                    }}>
                                    Delete
                                </button>
                            )}
                            {(adminData.role === "super-admin" && (loanData.status === "Funded" || loanData.status === "Funded This Month")) && (
                                <button onClick={addToBook} className='btn bg-primary hover:text-gray-500 hover:bg-white text-white' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}>
                                    Add to Book
                                </button>
                            )}
                            <div className="drawer drawer-end">
                                {adminData.role === "super-admin" && <input id="my-drawer" type="checkbox" className="drawer-toggle" />}
                                <div className="drawer-content">
                                    {/* Page content here */}
                                    <label htmlFor="my-drawer" className="btn bg-primary hover:text-gray-500 hover:bg-white text-white drawer-button">
                                        History
                                    </label>
                                </div>
                                <div className="drawer-side w-full z-50">
                                    <label htmlFor="my-drawer" className="drawer-overlay"></label>
                                    <div className="menu p-4 w-full min-h-full bg-base-200 text-base-content relative">
                                        {/* Close icon */}
                                        <label htmlFor="my-drawer" className="absolute text-4xl right-10 top-10 cursor-pointer text-gray-500">
                                            &#10005;
                                        </label>
                                        {/* Sidebar content here */}
                                        <h1 className='text-3xl font-bold uppercase text-center mb-5 mt-20'>Timeline</h1>
                                        <div className="container mx-auto">
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full border-base-300 border-2">
                                                    <thead className="bg-base-200">
                                                        <tr>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Name</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Date</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Time</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Field</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Previous Value</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">New Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            history.length !== 0 && history.map((timelineHistory, i) => (<tr key={i} className='hover:bg-base-300'>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.updatedBy}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.date}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.time}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.field}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.oldValue}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.newValue}</td>
                                                            </tr>))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Step Progress Bar */}
                <div className='flex items-center justify-center mr-10 my-10'>
                    <ul className="steps">
                        <li className={`step ${statusStep >= 1 && "step-primary"}`}>Initial Review</li>
                        <li className={`step ${statusStep >= 3 && "step-primary"}`}>Golden Data</li>
                        <li className={`step ${statusStep >= 4 && "step-primary"}`}>The Great Takeover</li>
                        <li className={`step ${statusStep >= 5 && "step-primary"}`}>Pre-Underwriting</li>
                        <li className={`step ${statusStep >= 6 && "step-primary"}`}>Pre-Offer</li>
                        <li className={`step ${statusStep >= 7 && "step-primary"}`}>Underwriting</li>
                        <li className={`step ${statusStep >= 8 && "step-primary"}`}>Extra docs ob</li>
                        <li className={`step ${statusStep >= 9 && "step-primary"}`}>Awaiting Offer</li>
                        <li className={`step ${statusStep >= 10 && "step-primary"}`}>Offer</li>
                        <li className={`step ${statusStep >= 11 && "step-primary"}`}>Contract Sent</li>
                        <li className={`step ${statusStep >= 12 && "step-primary"}`}>Awaiting Funding</li>
                        <li className={`step ${statusStep >= 13 && "step-primary"}`}>Funded This Month</li>
                        <li className={`step ${statusStep >= 14 && "step-primary"}`}>Funded</li>
                        <li className={`step ${statusStep >= 15 && "step-primary"}`}>Cancelled</li>
                    </ul>
                </div>
                {/* End Step Progress Bar */}


                <div className='flex gap-3 flex-wrap bg-base-300 p-5 rounded-xl'>
                    <button onClick={() => setShowFundingHistory(true)} className={`btn hover:bg-primary hover:text-white ${showFundingHistory === true && 'bg-primary text-white'}`}>
                        Funding History
                    </button>
                    {lenderArray.map((item, index) => <button onClick={() => (setActiveLender(item), setShowFundingHistory(false), changeLenderValues(item))} key={index} className={`btn hover:bg-primary hover:text-white ${(activeLender === item && showFundingHistory === false) && 'bg-primary text-white'}`}>
                        {item}
                    </button>)}
                </div>

                {
                    showFundingHistory === true && <div className='mt-5'>
                        <div className='mb-5 flex gap-5 items-end'>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text">Date Funded:</span>
                                </div>
                                <input
                                    type="date"
                                    className="input input-sm input-bordered w-full"
                                    value={dateFunded}
                                    onChange={(e) => setDateFunded(e.target.value)}
                                />
                            </label>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text">Funder: </span>
                                </div>
                                <select
                                    id='funder'
                                    className="input input-sm input-bordered w-full"
                                    value={funder}
                                    onChange={(e) => setFunder(e.target.value)}
                                >
                                    <option value="">Select a funder</option>
                                    <option value="Liberis">Liberis</option>
                                    <option value="Bizcap">Bizcap</option>
                                    <option value="Youlend">Youlend</option>
                                    <option value="365 Finance">365 Finance</option>
                                    <option value="Sedge">Sedge</option>
                                    <option value="Capify">Capify</option>
                                    <option value="IWOCA">IWOCA</option>
                                    <option value="Swoop">Swoop</option>
                                    <option value="Funding Alt">Funding Alt</option>
                                    <option value="Senica">Senica</option>
                                    <option value="Maxcap">Maxcap</option>
                                    <option value="Swiftfund">Swiftfund</option>
                                    <option value="Funding Circle">Funding Circle</option>
                                    <option value="Playter">Playter</option>
                                    <option value="Penny">Penny</option>
                                </select>
                            </label>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text">Loan Amount: </span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="input input-sm input-bordered w-full"
                                    value={loanAmount}
                                    onChange={(e) => setLoanAmount(e.target.value)}
                                />
                            </label>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text">Repayment: </span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="input input-sm input-bordered w-full"
                                    value={repaymentAmount}
                                    onChange={(e) => setRepaymentAmount(e.target.value)}
                                />
                            </label>
                            <button
                                className="btn bg-primary btn-sm text-white"
                                onClick={handleFundingHistorySave}
                            >
                                Save
                            </button>
                        </div>
                        <div className="overflow-x-auto border mt-2 border-base-300 rounded-xl">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Date Funded</th>
                                        <th>Due Renewal</th>
                                        <th>Est. Remaining</th>
                                        <th>% Remaining</th>
                                        <th>Funder</th>
                                        <th>Loan Amount</th>
                                        <th>Repayment</th>
                                        <th>Factor</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loanData?.['funding-history']?.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, index) => (
                                        <tr key={index} className="hover">
                                            <th>
                                                {editingIndex === index ? (
                                                    <input
                                                        type="date"
                                                        className="input input-sm input-bordered w-full"
                                                        value={fundingDate || item?.date}
                                                        onChange={(e) => setFundingDate(e.target.value)}
                                                    />
                                                ) : (
                                                    new Date(item?.date).toLocaleDateString('en-GB')
                                                )}
                                            </th>
                                            <td>{calculateRenewalDate(item?.funder, item?.date)} </td>
                                            <td>{editingIndex === index ? (
                                                <input type="number" className="input input-bordered input-sm w-full max-w-xs" defaultValue={item.remaining} onChange={e => setFundingEstRemaining(e.target.value)} />
                                            ) : (
                                                `£ ${calculateRenewalDate(item?.funder, item?.date) === "Done" ? '0' : parseFloat(item?.['remaining']).toLocaleString()}`
                                            )}</td>
                                            <td>{Math.round(((calculateRenewalDate(item?.funder, item?.date) === "Done" ? 0 : parseFloat(item?.['remaining']) / parseFloat(item?.['repayment-amount']) * 100) || 0))} %</td>
                                            <td>
                                                {editingIndex === index ? (
                                                    <select
                                                        id='funder'
                                                        className="input input-sm input-bordered w-full"
                                                        value={fundingFunder || item.funder}
                                                        onChange={(e) => setFundingFunder(e.target.value)}
                                                    >
                                                        <option value="">Select a funder</option>
                                                        <option value="Liberis">Liberis</option>
                                                        <option value="Bizcap">Bizcap</option>
                                                        <option value="Youlend">Youlend</option>
                                                        <option value="365 Finance">365 Finance</option>
                                                        <option value="Sedge">Sedge</option>
                                                        <option value="Capify">Capify</option>
                                                        <option value="IWOCA">IWOCA</option>
                                                        <option value="Swoop">Swoop</option>
                                                        <option value="Funding Alt">Funding Alt</option>
                                                        <option value="Senica">Senica</option>
                                                        <option value="Maxcap">Maxcap</option>
                                                        <option value="Swiftfund">Swiftfund</option>
                                                        <option value="Funding Circle">Funding Circle</option>
                                                        <option value="Playter">Playter</option>
                                                    </select>
                                                ) : (
                                                    item.funder
                                                )}
                                            </td>
                                            <td>{editingIndex === index ? (
                                                <input type="number" className="input input-bordered input-sm w-full max-w-xs" defaultValue={item?.['loan-amount']} onChange={e => setFundingLoanAmount(e.target.value)} />
                                            ) : (
                                                `£ ${parseFloat(item?.['loan-amount']).toLocaleString()}`
                                            )}</td>
                                            <td>{editingIndex === index ? (
                                                <input type="number" className="input input-bordered input-sm w-full max-w-xs" defaultValue={item?.['repayment-amount']} onChange={e => setFundingRepayment(e.target.value)} />
                                            ) : (
                                                `£ ${parseFloat(item?.['repayment-amount']).toLocaleString()}`
                                            )}</td>
                                            <td>{(parseFloat(item?.['repayment-amount']) / parseFloat(item?.['loan-amount'])).toFixed(2)}</td>
                                            <td className='flex items-center gap-2'>
                                                {editingIndex === index ? (
                                                    <button className='btn btn-sm btn-primary' onClick={() => saveChanges(item)}>Save</button>
                                                ) : (
                                                    <button className='btn btn-sm' onClick={() => setEditingIndex(index)}><FiEdit className='text-lg' /></button>
                                                )}
                                                <button onClick={e => deleteFundingHistoryEntry(item)} className='btn btn-sm'><MdDeleteOutline className='text-xl' /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                {
                    showFundingHistory === false && <form className="mt-8 mr-10 mx-auto">
                        <div className='grid grid-cols-3 gap-3'>
                            {
                                adminData?.moveBack === false && <div>
                                    <label htmlFor="status" className='text-sm mb-1 block'>Status:</label>
                                    <select name='status' id='status' className="p-3 rounded-lg bg-base-300 w-full" onChange={e => setStatus(e.target.value)} defaultValue={loanData?.lenders[activeLender]?.status}>
                                        <option value={loanData["status"]}>{loanData?.lenders[activeLender]?.status}</option>
                                        {statusArray.map((status, index) => (
                                            user?.email === 'millie@sedgefunding.co.uk' || user?.email === 'smit@readysteadyfinance.co.uk' || adminData?.role === 'super-admin' ? <option value={status} key={index}>{status}</option> : statusArray.indexOf(loanData?.lenders[activeLender]?.status) < statusArray.indexOf(status) && <option value={status}>{status}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {
                                adminData?.moveBack !== false && <div>
                                    <label htmlFor="status" className='text-sm mb-1 block'>Status:</label>
                                    <select id='status' className="p-3 rounded-lg bg-base-300 w-full" onChange={e => setStatus(e.target.value)} defaultValue={loanData?.lenders[activeLender]?.status}>
                                        <option value={loanData?.lenders[activeLender]?.status}>{loanData?.lenders[activeLender]?.status || 'Not Selected'}</option>
                                        {statusArray.map((status, index) => (
                                            user?.email === 'millie@sedgefunding.co.uk' || user?.email === 'smit@readysteadyfinance.co.uk' || adminData?.role === 'super-admin' ? <option value={status} key={index}>{status}</option> : statusArray.indexOf(loanData["status"]) < statusArray.indexOf(status) && <option value={status}>{status}</option>
                                        ))}
                                    </select>
                                </div>
                            }

                            <div>
                                <label htmlFor="fundingManager" className='text-sm mb-1 block'>Funding Manager:</label>
                                <select id='fundingManager' className="p-3 rounded-lg bg-base-300 w-full" onChange={e => setFundingManager(e.target.value)} defaultValue={loanData['funding-manager'] || 'Funding Manager'}>
                                    <option value={loanData['funding-manager'] || 'Funding Manager'}>{loanData['funding-manager'] || 'Funding Manager'}</option>
                                    <option value="Wayne">Wayne</option>
                                    <option value="Peter">Peter</option>
                                    <option value="Jon">Jon</option>
                                    <option value="Millie">Millie</option>
                                    <option value="Smit">Smit</option>
                                    <option value="Mark">Mark</option>
                                    <option value="Becher">Becher</option>
                                    <option value="Don">Don</option>
                                    <option value="Carl">Carl</option>
                                    <option value="Jonnalou">Jonnalou</option>
                                    <option value="Janne">Janne</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor="kanbanView" className='text-sm mb-1 block'>On Kanban:</label>
                                <select id='kanbanView' className="p-3 rounded-lg bg-base-300 w-full" onChange={e => setShowInKanban(e.target.value)} defaultValue={loanData?.lenders[activeLender]?.['show-in-kanban'] || 'No'}>
                                    <option value={loanData?.lenders[activeLender]?.['show-in-kanban'] || 'No'}>{loanData?.lenders[activeLender]?.['show-in-kanban'] || 'No'}</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>

                            <div>
                                <label htmlFor="businessName" className=' text-sm mb-1 block'>Business Name:</label>
                                <input onChange={e => setBusinessName(e.target.value)} id='businessName' type="text" placeholder="Business Name" defaultValue={`${loanData["business-name"]}`} className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>
                            <div>
                                <label htmlFor="businessType" className=' text-sm mb-1 block'>Business Type:</label>
                                <select onChange={e => setBusinessType(e.target.value)} name='business-type' id='businessType' className="p-3 rounded-lg bg-base-300 w-full" defaultValue={loanData["business-type"] || ''} required>
                                    <option value={loanData["business-type"]} disabled>Current Type - {loanData["business-type"]}</option>
                                    <option value="Ltd">Ltd</option>
                                    <option value="PLC">PLC</option>
                                    <option value="LLP">LLP</option>
                                    <option value="Sole Trader">Sole Trader</option>
                                    <option value="Partnership">Partnership</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="businessAddress" className=' text-sm mb-1 block'>Business Address:</label>
                                <input onChange={e => setBusinessAddress(e.target.value)} defaultValue={`${loanData["business-address"]}`} name='business-address' id='businessAddress' type="text" placeholder="Business Address" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>

                            <div>
                                <label htmlFor="contactName" className=' text-sm mb-1 block'>Business Contact Name (Owner):</label>
                                <input onChange={e => setBusinessOwnerName(e.target.value)} defaultValue={`${loanData["business-owner-name"]}`} name='business-owner-name' type="text" placeholder="Contact Name" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>
                            <div>
                                <label htmlFor="dateOfBirth" className=' text-sm mb-1 block'>Date Of Birth:</label>
                                <input onChange={e => setBirthDate(e.target.value)} defaultValue={`${loanData["birth-date"]}`} type="date" placeholder="Date of birth" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>
                            <div>
                                <label htmlFor="home-address" className=' text-sm mb-1 block'>Home Address:</label>
                                <input onChange={e => setHomeAddress(e.target.value)} defaultValue={`${loanData["home-address"]}`} name='home-address' id='home-address' type="text" placeholder="Home Address" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>
                            <div>
                                <label htmlFor="businessEmail" className=' text-sm mb-1 block'>Business Email:</label>
                                <input onChange={e => setBusinessEmail(e.target.value)} defaultValue={`${loanData["business-email"]}`} name='business-email' id='businessEmail' type="text" placeholder="Email" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>
                            <div>
                                <label htmlFor="phone" className=' text-sm mb-1 block'>Business Phone Number:</label>
                                <input onChange={e => setBusinessPhone(e.target.value)} defaultValue={`${loanData["business-phone"]}`} name='business-phone' id='phone' type="text" placeholder="Phone Number" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>

                            <div>
                                <label htmlFor="homeOwner" className=' text-sm mb-1 block'>Homeowner / Renting:</label>
                                <input onChange={e => setHomeOwner(e.target.value)} defaultValue={`${loanData["home-owner"]}`} name='home-owner' id='homeOwner' type="text" placeholder="Home Owner / Renting" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>
                            <div>
                                <label htmlFor="businessTurnover" className=' text-sm mb-1 block'>Average Monthly Turnover:</label>
                                <input onChange={e => setAvarageMonthlyTurnover(e.target.value)} defaultValue={`${loanData["avarage-monthly-turnover"]}`} name='avarage-monthly-turnover' type="text" placeholder="Monthly Turnover" className="p-3 rounded-lg bg-base-300 w-full" />
                            </div>
                            {
                                (activeLender === "Bibby" || activeLender === "Penny") && <div>
                                    <label htmlFor="invoice-amount" className=' text-sm mb-1 block'>Invoice Amount:</label>
                                    <input onChange={e => setInvoiceAmount(e.target.value)} defaultValue={`${loanData["invoice-amount"]}`} name='invoice-amount' type="text" placeholder="Invoice Amount" className="p-3 rounded-lg bg-base-300 w-full" />
                                </div>
                            }
                            {
                                (activeLender === "YouLend" || activeLender === "365 Finance" || activeLender === "New Deal") && <div>
                                    <label htmlFor="marchent-turnover" className=' text-sm mb-1 block'>Marchent Turnover:</label>
                                    <input onChange={e => setMarchentTurnover(e.target.value)} defaultValue={`${loanData["marchent-turnover"]}`} name='marchent-turnover' type="text" placeholder="Marchent Turnover" className="p-3 rounded-lg bg-base-300 w-full" />
                                </div>
                            }
                            <div>
                                <label htmlFor="requiredAmount" className=' text-sm mb-1 block'>Required Amount:</label>
                                <input onWheel={handleWheel} onKeyDown={handleKeyDown} onChange={e => setRequiredAmount(e.target.value)} defaultValue={`${loanData["required-amount"]}`} name='required-amount' id='requiredAmount' type="number" placeholder="Required Amount" className="p-3 rounded-lg bg-base-300 w-full" required />
                            </div>
                            {
                                !(activeLender === "YouLend" || activeLender === "365 Finance" || activeLender === "Bibby" || activeLender === "Penny" || activeLender === "New Deal") && <div></div>
                            }
                            <div>
                                <label htmlFor="overdraft" className=' text-sm mb-1 block'>Overdraft Limit:</label>
                                <input onChange={e => setOverdraftLimit(e.target.value)} defaultValue={`${loanData["overdraft-limit"]}`} name='overdraft-limit' type="text" placeholder="Overdraft Limit" className="p-3 rounded-lg bg-base-300 w-full" />
                            </div>
                            <div>
                                <label htmlFor="loanPurpose" className=' text-sm mb-1 block'>Purpose of Loan:</label>
                                <input onChange={e => setLoanPurpose(e.target.value)} defaultValue={`${loanData["loan-purpose"]}`} name='loan-purpose' type="text" placeholder="Loan Purpose" className="p-3 rounded-lg bg-base-300 w-full" />
                            </div>
                            <div>
                                <label htmlFor="notes" className=' text-sm mb-1 block'>Note For Lender:</label>
                                <input onChange={e => setLenderNote(e.target.value)} defaultValue={`${loanData["lender-note"]}`} name='lender-note' type="text" placeholder="Notes" className="p-3 rounded-lg bg-base-300 w-full" />
                            </div>
                            {
                                activeLender !== "New Deal" && <div>
                                    <label htmlFor="offerAmount" className="text-sm mb-1 block">Offer amount:</label>
                                    <input
                                        onChange={e => (setOfferAmount(e.target.value), setOfferAmountLive(e.target.value))}
                                        id="fundedAmount"
                                        value={offerAmountLive}
                                        name="offer-amount"
                                        type="text"
                                        placeholder="Offer Amount"
                                        className="p-3 rounded-lg bg-base-300 w-full"
                                    />
                                </div>
                            }

                            {
                                activeLender !== "New Deal" && <div>
                                    <label htmlFor="invoice" className="text-sm mb-1 block">Offer Repayment / Funded:</label>
                                    <input
                                        name="invoice"
                                        id="invoice"
                                        type="text"
                                        placeholder="Offer Repayment / Funded"
                                        onChange={e => (setOfferRepaymentLive(e.target.value), setOfferRepayment(e.target.value))}
                                        value={offerRepaymentLive}
                                        className="p-3 rounded-lg bg-base-300 w-full"
                                        required
                                    />
                                </div>
                            }
                            {
                                adminData.role === 'super-admin' && activeLender !== "New Deal" && <div>
                                    <label htmlFor="companyComs" className=' text-sm mb-1 block'>Company Coms:</label>
                                    <input
                                        value={companyComsLive}
                                        onChange={e => (setCompanyComs(e.target.value), setCompanyComsLive(e.target.value))}
                                        name="companyComs"
                                        type="text"
                                        placeholder="Company Coms"
                                        className="p-3 rounded-lg bg-base-300 w-full"
                                    />
                                </div>
                            }
                            <div className='col-span-3'>
                                <label htmlFor="offer-notes" className=' text-sm mb-1 block'>Offer Notes:</label>
                                <input
                                    name='offer-notes'
                                    type="text"
                                    placeholder="Offer Notes"
                                    className="p-3 rounded-lg bg-base-300 w-full"
                                    value={offerNotesLive}
                                    onChange={e => (setOfferNotes(e.target.value), setOfferNotesLive(e.target.value))}
                                />
                            </div>
                            <div className='col-span-3'>
                                <label htmlFor="declien-notes" className=' text-sm mb-1 block'>Declien Notes:</label>
                                <input
                                    name='declien-notes'
                                    type="text"
                                    placeholder="Declien Notes"
                                    className="p-3 rounded-lg bg-base-300 w-full"
                                    value={declineNotesLive}
                                    onChange={e => (setDeclineNotes(e.target.value), setDeclineNotesLive(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className='flex justify-between items-center mt-5'>
                            <div className='flex items-center'>
                                <input type="date" id="date" name="date" value={selectedDate} onChange={handleDateChange} className='input border-gray-400 border mr-3' />
                                <select id="time" name="time" className='input border-gray-400 border mr-3' value={selectedTime} onChange={handleTimeChange}>
                                    <option value="">Select a time</option>
                                    {generateTimeSlots().map((timeSlot, index) => (
                                        <option key={index} value={timeSlot}>{timeSlot}</option>
                                    ))}
                                </select>
                                <button type='button' onClick={handleCallBackSubmit} className='btn bg-primary text-white'>Call Back</button>
                            </div>
                            {
                                loanData?.callBack && <div className='flex items-center'>
                                    <p className='text-lg font-semibold'>Call Back Time: {new Date(loanData?.callBack?.date).toLocaleDateString('en-UK', { day: 'numeric', month: 'numeric', year: 'numeric', }) + ' ,' + loanData?.callBack?.time}</p>
                                </div>
                            }
                            <div className='text-right'>
                                {
                                    activeLender === "New Deal" && <Link to={`/admin/new-kanban/new-pitch/${loanData._id}`} className='button rounded mr-3'>New Pitch</Link>
                                }
                                <Link to={`/admin/new-kanban/underwritting/${loanData._id}?lender=${activeLender}`} type='button' className='button rounded mr-3'>Underwritting</Link>
                                <button onClick={e => dealEditUpdate(false)} type='button' className='button rounded mr-3'>Submit Stay</button>
                                <button onClick={e => dealEditUpdate(true)} type='button' className='button rounded'>Submit Back</button>
                            </div>
                        </div>
                    </form>
                }

                {showFundingHistory === false && <div className='my-10 mr-10 flex gap-10 '>
                    <div className='min-w-[500px]'>
                        <div>
                            <table className="table p-5">
                                <thead>
                                    <tr className='border-0'>
                                        <th scope="col">
                                            <p>Required</p>
                                        </th>
                                        <th scope="col">
                                            <p>Title</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* bank6m */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="bank6m"
                                                defaultChecked={loanData?.documents?.bank6m?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "bank6m", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">
                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>6m Bank</span>
                                                        <input
                                                            type="checkbox"
                                                            name="bank6m"
                                                            defaultChecked={loanData?.documents?.bank6m?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "bank6m", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="bank6m"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="bank6m" id="bank6m" multiple onChange={(e) => handleFileChange(e, "bank6m")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.bank6m?.attachment ? (
                                                            Array.isArray(loanData?.documents?.bank6m?.attachment) ? (
                                                                loanData?.documents?.bank6m?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.bank6m?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* openBank */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="openBank"
                                                defaultChecked={loanData?.documents?.openBank?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "openBank", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>Open Bank</span>
                                                        <input
                                                            type="checkbox"
                                                            name="openBank"
                                                            defaultChecked={loanData?.documents?.openBank?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "openBank", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="openBank"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="openBank" id="openBank" multiple onChange={(e) => handleFileChange(e, "openBank")} />
                                                    </div>

                                                    <button onClick={copyToClipboard} className='btn bg-primary hover:bg-black mt-5 w-full btn-sm text-white text-sm'>
                                                        Copy Open Banking Link Here
                                                    </button>

                                                    <div>
                                                        {openBankingRequisition && !obloading && (
                                                            <div className='mt-5'>
                                                                <select id='openBankingAccountID' className='bg-base-300 input input-sm' defaultValue={openBankingRequisition.accounts[0]}>
                                                                    {accountData.map((account) => (
                                                                        <option key={account.id} value={account.id}>
                                                                            {account.iban}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <button onClick={exportData} className='btn btn-sm bg-primary hover:bg-black text-white ml-3'>Downoad</button>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.openBank?.attachment ? (
                                                            Array.isArray(loanData?.documents?.openBank?.attachment) ? (
                                                                loanData?.documents?.openBank?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.openBank?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* ID */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="id"
                                                defaultChecked={loanData?.documents?.id?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "id", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>ID</span>
                                                        <input
                                                            type="checkbox"
                                                            name="id"
                                                            defaultChecked={loanData?.documents?.id?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "id", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="id"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="id" id="id" multiple onChange={(e) => handleFileChange(e, "id")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.id?.attachment ? (
                                                            Array.isArray(loanData?.documents?.id?.attachment) ? (
                                                                loanData?.documents?.id?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.id?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.id?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* proofeOfAddress */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="proofeOfAddress"
                                                defaultChecked={loanData?.documents?.proofeOfAddress?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "proofeOfAddress", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>Proof Of Address</span>
                                                        <input
                                                            type="checkbox"
                                                            name="proofeOfAddress"
                                                            defaultChecked={loanData?.documents?.proofeOfAddress?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "proofeOfAddress", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="proofeOfAddress"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="proofeOfAddress" id="proofeOfAddress" multiple onChange={(e) => handleFileChange(e, "proofeOfAddress")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.proofeOfAddress?.attachment ? (
                                                            Array.isArray(loanData?.documents?.proofeOfAddress?.attachment) ? (
                                                                loanData?.documents?.proofeOfAddress?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.proofeOfAddress?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.proofeOfAddress?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* eya */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="eya"
                                                defaultChecked={loanData?.documents?.eya?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "eya", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>EYA</span>
                                                        <input
                                                            type="checkbox"
                                                            name="eya"
                                                            defaultChecked={loanData?.documents?.eya?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "eya", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="eya"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="eya" id="eya" multiple onChange={(e) => handleFileChange(e, "eya")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.eya?.attachment ? (
                                                            Array.isArray(loanData?.documents?.eya?.attachment) ? (
                                                                loanData?.documents?.eya?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.eya?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.eya?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* Other Bank Details */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="other"
                                                defaultChecked={loanData?.documents?.other?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "other", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>Other Documents</span>
                                                        <input
                                                            type="checkbox"
                                                            name="other"
                                                            defaultChecked={loanData?.documents?.other?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "other", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="other"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="other" id="other" multiple onChange={(e) => handleFileChange(e, "other")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.other?.attachment ? (
                                                            Array.isArray(loanData?.documents?.other?.attachment) ? (
                                                                loanData?.documents?.other?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.other?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='grow'>
                        <h2 className='font-semibold text-2xl'>Team Notes:</h2>
                        <div className='flex gap-2 mt-2'>
                            <input
                                name='teamNotes'
                                id='teamNotes'
                                type="text"
                                placeholder="Team Notes"
                                className="p-3 rounded-lg bg-base-300 w-full"
                                value={teamNote}
                                onChange={(e) => setTeamNote(e.target.value)}
                                required
                            />
                            <button className='button' onClick={handleNoteSubmit}>Submit</button>
                        </div>
                        <div className="mt-2 h-[355px] overflow-y-scroll">
                            {loanData?.lenders?.[activeLender]?.['team-notes'] && loanData?.lenders?.[activeLender]?.['team-notes'].length > 0 ? (
                                loanData?.lenders?.[activeLender]?.['team-notes'].slice().reverse().map((note, index) => (
                                    <div key={index}>
                                        {typeof note === 'object' ? (
                                            <div className='mb-3 bg-base-200 p-3 rounded-xl' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}>
                                                {note.name}
                                                <br />
                                                {note.note}
                                            </div>
                                        ) : (
                                            <div className='mb-3 bg-base-200 p-3 rounded-xl' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}>{note}</div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>No new notes</p>
                            )}
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    )
}

export default EditSingleDeal